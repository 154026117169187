import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DocumentPreviweComponent } from './document-previwe/document-previwe.component';
import { DocumentsComponent } from './documents/documents.component';




const routes: Routes = [
{
  path: 'documents',
  component: DocumentsComponent
},
{
  path: 'document_preview/:id',
  component: DocumentPreviweComponent
},
{
  path: '**',  
  redirectTo:'documents'
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
