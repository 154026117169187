import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

/**
*Event / Internal Message Listeners
*/
@Injectable({providedIn: 'root'})
export class MessageService {
  private subject = new Subject<any>();
  private progressbar = new Subject<any>();  
  private reloadModule=new Subject<any>();
  private cv_section_header = new Subject<any>();
  private active_module = new Subject<any>();
  private individual = new Subject<any>();
  private individual_contact = new Subject<any>();
  private filter_fields = new Subject<any>();

  sendMessage(message: string) {
    this.subject.next({text: message});
  }

  clearMessage() {
    this.subject.next();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  showLoader(op: boolean) {
    this.progressbar.next(op);
  }

  loaderListener(): Observable<any> {
    return this.progressbar.asObservable();
  }

  setReload(op:boolean){
    this.reloadModule.next(op);
  }

  reloadListener():Observable<any>{
    return this.reloadModule.asObservable();
  }

  setCVSectionHeader(op:any){
    this.cv_section_header.next(op);
  }

  CVSectionHeaderListener():Observable<any>{
    return this.cv_section_header.asObservable();
  }

  setActiveModule(op: any) {
    this.active_module.next(op);
  }

  activeModuleListener(): Observable<any> {
    return this.active_module.asObservable();
  }

  setIndividualSelecter(op: any) {
    this.individual.next(op);
  }

  individualSelecterListener(): Observable<any> {
    return this.individual.asObservable();
  }
  
  setIndividualContactSelecter(op: any) {
    this.individual_contact.next(op);
  }

  individualContactSelecterListener(): Observable<any> {
    return this.individual_contact.asObservable();
  }

  setListFilterSelecter(op: any) {
    this.filter_fields.next(op);
  }

  listFilterSelecterListener(): Observable<any> {
    return this.filter_fields.asObservable();
  }
}