import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {NgbPaginationModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

import { MatFormFieldModule, MatSelectModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterial } from  './angular.material';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent} from './app.component';
import { LoginComponent } from './login/login.component';
import {ToolsComponent,DateparseComponent,FetcherValue,FormFieldValue,SelectSearchPipe,NoSanitizePipe,HTMLTextDisplay,GetCountryName,GetUserName,GetOrgNameByIndividual} from './tools/tools.component';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import {alertDialog} from './shared.service';
import {SharedService} from './shared.service';
import { DocumentPreviweComponent } from './document-previwe/document-previwe.component';
import { DocumentsComponent } from './documents/documents.component';

@NgModule({
  declarations: [
  AppComponent,
  alertDialog,
  LoginComponent,ToolsComponent,DateparseComponent,FetcherValue,FormFieldValue,
  SelectSearchPipe,NoSanitizePipe,HTMLTextDisplay,GetCountryName,GetUserName,GetOrgNameByIndividual,
  DocumentPreviweComponent, DocumentsComponent
  ],
  imports: [
  BrowserModule,
  HttpClientModule,
  AppRoutingModule,
  AngularMaterial,
  NgbPaginationModule, NgbAlertModule,
  FormsModule,ReactiveFormsModule,
  AngularMultiSelectModule, MatSelectModule,
  MatFormFieldModule,
  NgxMatSelectSearchModule,
  BrowserAnimationsModule
  ],
  providers: [SharedService,{provide: MAT_DATE_LOCALE, useValue: "en-IN"},
  {
    provide: APP_INITIALIZER,
    useFactory: (sharedService: SharedService) =>
    () => sharedService.init(),
    deps: [SharedService],
    multi: true
  }],
  bootstrap: [AppComponent],
  entryComponents:[alertDialog]

})
export class AppModule { }