import {Injectable} from '@angular/core';
import {Router,ActivatedRoute} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

import {DbactService} from './dbact.service';
import {SharedService} from './shared.service';
import { CrudService } from './crud.service';
import {MessageService} from './message.service';
import {ValidationService} from './validation.service';

import * as _ from 'lodash';

/**
 * @ignore
 */
 @Injectable({
   providedIn: 'root'
 })
 export class AuthService {
   // private loggedIn = new BehaviorSubject<boolean>(false); // {1}
   constructor(
     private router: Router,
     private dbact: DbactService,
     private activeRoute: ActivatedRoute,
     public shared:SharedService,
     public validationutils:ValidationService,
     public messageService: MessageService,
     private crudutils: CrudService,
     ) {}

   response_messages = this.shared.response_messages;

   isLoggedIn() {
     if (localStorage.login) {
       return true;
     } else {
       return false;
     }
   }

   login(userDetails) {
     return new Promise((resolve, reject) => {
       if (userDetails.username == undefined || userDetails.password == undefined) {
         return resolve(false);
       }
       this.messageService.showLoader(true);
       this.dbact.performLogin("login/", "POST", userDetails).subscribe((response: any) => {
         // console.log("login res:", response);
         this.messageService.showLoader(false);
           if (response && response.login_info && response.login_info.UserId) {
           var login_info=response.login_info;
           localStorage.loggedInUserId = login_info.UserId;
           localStorage.name = login_info.name;
           localStorage.auth_token = login_info.auth_token;
           localStorage.login = true;
           this.processRoleData(response.role_info,response.org_info).then((res:any)=>{
             return resolve(true);  
           });   
         } else {
           this.shared.showAlert("Invalid Credential", "Something was wrong.Check your Username and Password");
           return resolve(false);
         }
       }, error => {
         // console.error("Login Error", error);
         this.messageService.showLoader(false);
         if(error && error.error && error.error.message && error.error.message.indexOf('Deactivated')!=-1){
           this.shared.showAlert("Alert",error.error.message);
         }else{
           this.shared.showAlert("Invalid Credential", "Something was wrong.Check your Username and Password");
         }
         return resolve(false);
       });
     });
   }

   signup(signUpdata,module){
     return new Promise((resolve,reject)=>{
       signUpdata.data.user_name=signUpdata.data.email+","+signUpdata.data.phone;
       localStorage.username=signUpdata.data.email;
       var valid:any=this.validationutils.signUp(signUpdata);
       // console.log("sign up valid",valid);
       if (valid==true) {
         var postObject=_.cloneDeep(signUpdata);
         var formDataObject=new FormData();
         var addObject=JSON.parse(JSON.stringify(postObject));
         formDataObject.append("content",JSON.stringify(addObject));

         this.crudutils.commonURL(module+"/","POST",null,formDataObject).then((response:any)=>{
           if (response) {
             resolve(response);
             this.shared.openSnackBar(this.response_messages.success.signup.msg,this.response_messages.success.signup.sec);

             this.login({dbname:'xgportal',"customer_login":true, username: postObject.data.email, password: postObject.data.password}).then((response:any)=>{
               if (response) {
                 resolve(response);
               }else{
                 resolve(false);
               }
             });
           }else{
             resolve(false);
           } 
         });
       }else{
         this.shared.showAlert("Alert!",valid);
         resolve(false);
       }
     });
   }

   processRoleData(response,org_info){
     return new Promise((resolve,reject)=>{
       var permissions={},that=this;
       var configs=this.shared.configs;
       var role={names:[],user_role_ids:[]};

       var super_admin=_.filter(response.user_role_attribute_linked_info,{Module:'super_admin'})[0];
       if(super_admin){
         role.names=["Super Admin"];
         role['super_admin']=true;
       }

       var admin=_.filter(response.user_role_attribute_linked_info,{Module:'admin'})[0];
       if(admin){
         role.names=["Admininstrator"];
         role['admin']=true;
       }

       if(super_admin || admin){
         localStorage.role_data=JSON.stringify(role);
         return resolve(true);         
       }

       _.each(response.user_role_linked_info,function(attri_obj){         
         role.names.push(attri_obj.RoleName);
         role.user_role_ids.push(attri_obj.UserRoleId);
       });

       var self_organization=_.filter(response.user_role_attribute_linked_info,{Module:'self_organization'})[0];
       if(self_organization){
         role['self_organization']=true;
         role['org_view']=true;        
       }

       if(org_info){
         role['org_id']=org_info.OrganizationId;
         role['org_name']=org_info.OrganizationName;
       }

       _.each(response.user_role_attribute_linked_info,function(attri_obj){
         attri_obj['inner_view_tabs']=[];
         var local_config=_.filter(configs,{role_module:attri_obj.Module})[0];
         if(local_config && local_config.inner_view_tabs && local_config.inner_view_tabs.length){
           _.each(response.user_role_attribute_linked_info,function(attri_obj_2){
             var tab_filter=_.filter(local_config.inner_view_tabs,{role_module:attri_obj_2.Module});
             if(tab_filter && tab_filter[0]){
               attri_obj['inner_view_tabs'].push(attri_obj_2);
             }
           });         
         }
       });

       _.each(response.user_role_attribute_linked_info,function(attri_obj){
         var local_config=_.filter(configs,{role_module:attri_obj.Module})[0];
         // console.log("local_config",local_config);
         var key=attri_obj.Access;
         if(local_config){
           if(permissions[attri_obj.Module]){       
             permissions[attri_obj.Module].access[key]=true;
           }else{         
             permissions[attri_obj.Module]={
               name:local_config.module           
             };
             permissions[attri_obj.Module].access={tabs:[]};
             permissions[attri_obj.Module].access[key]=true;
           }
           that.processInnerviewTabs(permissions[attri_obj.Module].access,local_config.inner_view_tabs,attri_obj);
         }
       });
       // console.log("permissions",permissions);
       role['permissions']=permissions;

       localStorage.role_data=JSON.stringify(role);
       setTimeout(function(){
         resolve(true);
       },500);
     });
   }

   processInnerviewTabs(carry_data,inner_view_tabs,attri_obj){
     // console.log("inner_view_tabs",inner_view_tabs,"attri_obj",attri_obj);
     if(inner_view_tabs && inner_view_tabs.length && attri_obj.inner_view_tabs.length){
       _.each(attri_obj.inner_view_tabs,function(attri_obj_tab){
         var filter=_.filter(inner_view_tabs,{role_module:attri_obj_tab.Module})[0];
         // console.log("filter",filter);
         if(filter){
           var tab_filter=_.filter(carry_data.tabs,{name:filter.name})[0];         
           var key=attri_obj_tab.Access;
           // console.log("tab_filter",tab_filter,"key",key);
           if(tab_filter){
             tab_filter[key]=true;
           }else{
             var obj={name:filter.name};
             obj[key]=true;
             carry_data.tabs.push(obj);
           }
         }
       });

     }     
   }


 }