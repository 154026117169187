  import {Injectable} from '@angular/core';
  import { Observable, Subject, asapScheduler, pipe, of, from, interval, merge, fromEvent } from 'rxjs';
  import {HttpClient, HttpHeaders} from '@angular/common/http';
  import {SharedService} from './shared.service';


/**
*All database related CRUD calls made from here
*/
@Injectable({
  providedIn: 'root'
})
export class DbactService {
  httpOptions:any;
  constructor(
    private http: HttpClient,
    public shared: SharedService
    ) {
    if(localStorage.login){
      this.httpOptions={
        headers: new HttpHeaders({
          'Authorization': "Basic "+ localStorage.auth_token
        })
      }
    }
  }

  getLocalJson(url){
    return this.http.get(this.shared.ApiEndpoint.models_url + url);
  }

   /**
 * @ignore
 */
 performLogin(url, method, params) {
   return this.http.post(this.shared.ApiEndpoint.local_host_url + this.shared.ApiEndpoint.common_domain_url + url, params);
 }

  /**
  *Common method for making HTTP CRUD operations
  */

  performCRUDOperations(params, url, method) {
    return new Promise((resolve, reject) => {
      switch (method) {
        case "GET":
        return this.getData(params, url).subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        })
        case "POST":
        return this.postData(params, url).subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        })
        case "PUT":
        return this.putData(params, url).subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        })
        case "DELETE":
        return this.deleteData(params, url).subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        })
      }
    });
  }

  getData(params, url) {
    return this.http.get(this.shared.ApiEndpoint.local_host_url + url, this.httpOptions);
  }

  postData(params, url) {
    return this.http.post(this.shared.ApiEndpoint.local_host_url + url, params,this.httpOptions);
  }

  putData(params, url) {
    return this.http.put(this.shared.ApiEndpoint.local_host_url + url, params,this.httpOptions);
  }

  deleteData(params, url) {
    return this.http.delete(this.shared.ApiEndpoint.local_host_url + url,this.httpOptions);
  }

  getExportBuffer (url) {
    return this.http.get(this.shared.ApiEndpoint.local_host_url + this.shared.ApiEndpoint.common_domain_url + url, {headers: {'Authorization': JSON.stringify({"user_token": localStorage.auth_token})}, responseType: 'arraybuffer'});
  }
  importDocument(postdata, module) {   
    return this.http.post(this.shared.ApiEndpoint.local_host_url + this.shared.ApiEndpoint.common_domain_url + module + "/import/", postdata, {headers: {'Authorization': JSON.stringify({"user_token": localStorage.auth_token})}, responseType: 'arraybuffer'});
  }

  URLDownloader(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob'
    })
  }
}
