import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';


/**
* Internal method for computing and transforming HTTP CRUD data when app loads
*/
@Injectable({
  providedIn: 'root'
})

export class InitappService {
  httpOptions:any;
  constructor(
    private http: HttpClient
    ) {
    this.httpOptions={
      headers: new HttpHeaders({
        'Authorization': JSON.stringify({"user_token": localStorage.auth_token})
      })
    }
  }

  performCRUDOperations(url, method,params?) {
    return new Promise((resolve, reject) => {
      switch (method) {
        case "GET":
        return this.getData(params, url).subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        })
        case "POST":
        return this.postData(params, url).subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        })
        case "PUT":
        return this.putData(params, url).subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        })
        case "DELETE":
        return this.deleteData(params, url).subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        })
      }
    });
  }

  getData(params, url) {
    return this.http.get(url, this.httpOptions);
  }

  postData(params, url) {
    return this.http.post( params,this.httpOptions);
  }
  
  putData(params, url) {
    return this.http.put( params,this.httpOptions);
  }
  
  deleteData(params, url) {
    return this.http.delete(this.httpOptions);
  }
}