import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import * as _ from 'lodash';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {Subscription} from 'rxjs';
import * as FileSaver from 'file-saver';

import {CrudService} from '../crud.service';
import {SharedService} from "../shared.service";
import {MessageService} from '../message.service';

@Component({
	selector: 'app-documents',
	templateUrl: './documents.component.html',
	styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

	constructor(
		private router: Router,
		private activeRoute: ActivatedRoute,
		private crudutils: CrudService,
		public shared: SharedService,
		public dialog: MatDialog,
		public messageService: MessageService
		) { }

	fileBaseURL:string=this.shared.ApiEndpoint['documents_blob_holder'];
	current_state:any;
	ngOnInit() {
		if(sessionStorage['current_state']){
			this.current_state=JSON.parse(sessionStorage['current_state']);	
		}else{
			this.current_state={};
		}
		// console.log("current_state",this.current_state)
		this.initFilters();
		this.initSessionStorage();
		this.getConfig();
	}

	initSessionStorage(){
		if(this.current_state['page_size']){
			this.pageSize=this.current_state['page_size'];
		}
		if(this.current_state['page']){
			this.page=this.current_state['page'];
		}
		if(this.current_state['page']){
			this.skip=this.current_state['skip'];
		}
		if(this.current_state['header_sort']){
			this.headerSortField=this.current_state['header_sort'];
		}
		if(this.current_state['sort']){
			this.sortField=this.current_state['sort'];
		}
		if(this.current_state['search_text']){
			this.searchText=this.current_state['search_text'];
		}
	}

	c_module:string="documents";
	config:any;
	getConfig(){
		this.loaded=false;
		this.config = _.cloneDeep(this.shared.configs[this.c_module]);
		this.sortInit();
		this.getList(this.skip);
		this.initHeaderSortField();
		this.locField=this.config.loc_field;
		this.typeField=this.config.type_field;
	}

	initHeaderSortField(){
		if(!this.headerSortField){
			this.headerSortField=_.cloneDeep(this.config.head_sort_fields[0]);	
		}
	}

	locField:any;typeField:any;

	list:any;

/**  
* Set true when List call gets completed
*/
loaded:boolean=false;

/**  
* Used for Pagination
*/
skip:number=0;

/**  
* Used for Pagination
*/
pageSize:number=20;

/**  
* Used for Pagination
*/
page:number=1;

/**  
* Total number of rows count in the list
*/
totalRecords:number;

/**  
* To check list search is in progress
*/
searching:boolean;

/**  
* It holds search text
*/
searchText:string="";

/**  
* Load List
*/
selectedFilter:any={Category:'_all',DocumentTypeId:'_all'};
appliedFilters:any;
getList(search?){
	// return;
	this.messageService.showLoader(true);
	var search_text="",filter,sort="";

	if(this.searchText && this.searchText.length){
		search_text="&searchtext="+this.searchText;
	}
	this.searching=false;
	if(search){
		this.searching=true;
	}

	if(this.selectedFilter){
		this.appliedFilters=[];
		for (var key in this.selectedFilter) {
			if (this.selectedFilter.hasOwnProperty(key)) { 
				var value = this.selectedFilter[key];
				if(value!=null && value.indexOf('_all')==-1 && value.toString().trim().length){
					if(Array.isArray(value)){
						// console.log("typeof value[0]",typeof value[0]);
						if(typeof value[0]=='string'){
							var string_val="";
							_.each(value,function(val,index){
								string_val+="'"+val+"'";
								if(index+1<value.length){
									string_val+=",";
								}
							});
							this.appliedFilters.push({name:key,value:string_val,custom_handling:"multimatch_filter"});
						}else{
							this.appliedFilters.push({name:key,value:value.toString(),custom_handling:"multimatch_filter"});  
						}            
					}else{
						this.appliedFilters.push({name:key,value:value});
					}
				}
			}
		}
		// console.log("this.appliedFilters",this.appliedFilters);
		var filters=_.cloneDeep(this.appliedFilters);
		filters.push({name:"IsPublic",value:"1"});
		if(filters.length){		
			filter="&custom_filter="+JSON.stringify(filters);
		}else{
			filter="&custom_filter=[]";
		}
	}

	if(this.sortField){
		sort="&sortby="+this.sortField['name'];
		if(this.sortField['descending']){
			sort+="&descending=true";
		}
	}

	if(this.headerSortField){
		sort="&sortby="+this.headerSortField['name'];
		if(this.headerSortField['descending']){
			sort+="&descending=true";
		}
	}

	this.crudutils.commonURL(this.config.list_url+"?limit="+this.pageSize+"&skip="+this.skip+search_text+filter+sort,"GET").then((response:any)=>{
		this.messageService.showLoader(false);
		// console.log("list res",response);
		if(response){
			if(response.length){
				if(response[0].rows){
					this.list=response[0].rows;
					this.totalRecords=response[0].Count;
				}else{
					this.list=response;
					this.totalRecords=response.length;
				}
			}else{
				this.list=[];
				this.totalRecords=0;
			}  
		}
		this.loaded=true;
		this.searching=false;
	});
}

setStatetoStorage(type,data?){
	switch (type) {
		case "filter":
		this.current_state['list_filters']=this.selectedFilter;
		sessionStorage['current_state']=JSON.stringify(this.current_state);
		break;
		case 'sort':
		this.current_state['header_sort']=this.headerSortField;
		this.current_state['sort']=this.sortField;
		sessionStorage['current_state']=JSON.stringify(this.current_state);
		break;
		case 'page':
		this.current_state['page_size']=this.pageSize;
		this.current_state['page']=this.page;
		this.current_state['skip']=this.skip;
		sessionStorage['current_state']=JSON.stringify(this.current_state);
		break;
		case 'search_text':
		this.current_state['search_text']=this.searchText;
		sessionStorage['current_state']=JSON.stringify(this.current_state);
		break;
	}
}

filter_timeout:any;
typeSearchVal:any="";
categorySearchVal:any="";

applyFilters(filters){
	this.setStatetoStorage('filter');
	if(this.filter_timeout){
		clearTimeout(this.filter_timeout);
		this.filter_timeout=undefined;
	}
	this.filter_timeout=setTimeout(()=>{
		this.getList(this.skip);
	},500);
}

resetFilters(){
	this.selectedFilter={};
	if(this.config.module=="balls" || this.config.module=="tables"){			
		this.selectedFilter.Type=[];
	}
	else if(this.config.module=="LARC"){
		this.selectedFilter.PimpleType=[];
	}
}

cancelFilters(){
	this.current_state['list_filters']={};
	sessionStorage['current_state']=JSON.stringify(this.current_state);
	this.searchText="";
	this.resetFilters();
	this.getList(this.skip);
}

headerSortField:Object;

headerSortChange(field){
	if(this.headerSortField['name']==field.name){
		field['descending']=!field['descending'];
	}else{
		field['descending']=false;
	}
	this.headerSortField=_.cloneDeep(field);
	this.setStatetoStorage('sort');
	this.getList(this.skip);
}

sorting:Array<Object>;sortField:any;
sortInit(){
	this.sorting=this.config.sort_fields;
	this.sortField=this.sorting[0];
}

sortChange(sortfield){
	this.setStatetoStorage('sort');
	this.getList(this.skip);
}

pageSizes:any=[10,20,50,75,100];
pageSizeChange(size){
	// console.log("size",size);
	this.skip=0;
	this.setStatetoStorage('page');
	this.getList(this.skip);
}

pageChange(page){
	this.skip=(page-1)*this.pageSize;
	if(page==1){
		this.skip=0;
	}
	this.page=page; 
	this.setStatetoStorage('page'); 
	this.getList();
}

searchTimeout:any;
search(text){
	this.page = 1;
	if(this.searchTimeout){
		clearTimeout(this.searchTimeout);
	}
	var that=this;
	this.searchTimeout=setTimeout(function (){
		that.searchText=text;
		that.setStatetoStorage('search_text');
		that.getList(0);  
	},500);
}


gotoDetails(doc){
	this.router.navigateByUrl("/document_preview/"+doc.DocumentId);
}

categories:any;types:any;
initFilters(){
	if(this.current_state['list_filters']){
		this.selectedFilter=this.current_state['list_filters'];
	}else{
		this.selectedFilter={};
	}
	this.getDocTypes();
	this.categories=[{name:"AGM and BoD Documents",display:"AGM and BoD Documents"},{name:"Annual Report",display:"Annual Report"},{name:"Annual General Meeting",display:"Annual General Meeting"},{name:"Audited Financial Statements",display:"Audited Financial Statements"},{name:"Board of Directors",display:"Board of Directors"},{name:"Disciplinary Decisions",display:"Disciplinary Decisions"},{name:"Executive Committee",display:"Executive Committee"},{name:"Olympic Paralympic Commission",display:"Olympic Paralympic Commission"},{name:"Policies",display:"Policies"},{name:"Regulations",display:"Regulations"},{name:"Strategic Plan",display:"Strategic Plan"}];

}

getDocTypes(){
	this.crudutils.commonURL("DocumentTypes","GET").then((res:any)=>{
		if(res){
			var types=[];
			_.each(res,function(row){
				types.push({name:row.DocumentTypeId,display:row.DocumentTypeName});
			});
			this.types=types;
		}
	})
}

copyLinkToClipboard(val:string){
	let selBox = document.createElement('textarea');
	selBox.style.position = 'fixed';
	selBox.style.left = '0';
	selBox.style.top = '0';
	selBox.style.opacity = '0';
	selBox.value = val;
	document.body.appendChild(selBox);
	selBox.focus();
	selBox.select();
	document.execCommand('copy');
	document.body.removeChild(selBox);
	this.shared.showAlert("","Link copied to clipboard");
}

download(data){
	// fileBaseURL+data.DocumentURL
	// console.log("data",data);
	this.messageService.showLoader(true);
	this.crudutils.commonURL("pdfexport","GET").then((response:any)=>{
		// console.log("download RESPONSE",response);
		this.messageService.showLoader(false);
		if(response){
			FileSaver.saveAs(response,"test");
		}
	});
}

}
