import { NgModule } from '@angular/core';

import {
  MatAutocompleteModule, MatBadgeModule, MatBottomSheetModule, MatButtonModule,
  MatButtonToggleModule, MatCardModule,  MatCheckboxModule,  MatChipsModule,
  MatDatepickerModule,  MatDialogModule,  MatDividerModule,  MatExpansionModule,
  MatGridListModule,  MatIconModule,  MatInputModule,  MatListModule,
  MatMenuModule,  MatNativeDateModule,  MatPaginatorModule,  MatProgressBarModule,
  MatProgressSpinnerModule,  MatRadioModule,  MatRippleModule,  MatSelectModule,
  MatSidenavModule,  MatSliderModule,  MatSlideToggleModule,  MatSnackBarModule,
  MatSortModule,  MatStepperModule,  MatTableModule,  MatTabsModule,
  MatToolbarModule,  MatTooltipModule,  MatTreeModule} from '@angular/material';

  @NgModule({
    declarations:[],   
    exports:[
    MatAutocompleteModule, MatBadgeModule, MatBottomSheetModule, MatButtonModule,
    MatButtonToggleModule, MatCardModule,  MatCheckboxModule,  MatChipsModule,
    MatDatepickerModule,  MatDialogModule,  MatDividerModule,  MatExpansionModule,
    MatGridListModule,  MatIconModule,  MatInputModule,  MatListModule,
    MatMenuModule,  MatNativeDateModule,  MatPaginatorModule,  MatProgressBarModule,
    MatProgressSpinnerModule,  MatRadioModule,  MatRippleModule,  MatSelectModule,
    MatSidenavModule,  MatSliderModule,  MatSlideToggleModule,  MatSnackBarModule,
    MatSortModule,  MatStepperModule,  MatTableModule,  MatTabsModule,
    MatToolbarModule,  MatTooltipModule,  MatTreeModule
    ]

  })

  export class AngularMaterial{}