import { Component,Inject, Injectable, OnInit,Input,ViewChild,ElementRef,Pipe,PipeTransform } from '@angular/core';
// declare var PIXI:any;
import * as _ from 'lodash';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {Router, ActivatedRoute} from '@angular/router';

import { DomSanitizer,SafeHtml } from '@angular/platform-browser';
import { CrudService } from '../crud.service';
import {SharedService} from "../shared.service";
import {MessageService} from '../message.service';
import { AuthService } from '../authservice.service';
import {FormBuilder, FormGroup, Validators,FormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import { BlobService, UploadConfig, UploadParams } from 'angular-azure-blob-service';
import * as moment from 'moment';

/**
* All common and reusable components specified here.
*
* Can be accessed by entire application
*/


/**  
* @ignore
*/
@Component({
	selector: 'app-tools',
	templateUrl: './tools.component.html',
	styleUrls: ['./tools.component.css']
})
export class ToolsComponent implements OnInit {

	constructor(
    private router: Router,
    public crudutils:CrudService,
    public authservice:AuthService,
    public messageService: MessageService,
    public dialog: MatDialog,
    public shared: SharedService
    ) { }


	ngOnInit() {

	}

}


@Component({
  selector: 'app-country-name',
  templateUrl: './country_name.html',
  styleUrls: ['./tools.component.css']
})
export class GetCountryName implements OnInit {

  constructor(
    private router: Router,
    public crudutils:CrudService,
    public authservice:AuthService,
    public messageService: MessageService,
    public dialog: MatDialog,
    public shared: SharedService
    ) { }
  @Input('org_id') org_id:any;
  @Input('country_code') country_code:any;

  ngOnInit() {
    if(this.org_id){
      this.getOrg(this.org_id);
    }else if(this.country_code){
      this.getCountryName(this.country_code);
    }
  }

  getOrg(id){
    this.crudutils.commonURL("Organization/"+id,"GET").then((response:any)=>{
      if(response){
        this.getCountryName(response.CountryCode);
      }
    });
  }

  country:any;
  getCountryName(country_code){
    this.crudutils.commonURL("Country_by_code?country_code="+country_code,"GET").then((response:any)=>{
      if(response){
        this.country=response;
      }
    });
  }
}

@Component({
  selector: 'orgname-by-individual',
  templateUrl: './orgname_by_individual.html',
  styleUrls: ['./tools.component.css']
})
export class GetOrgNameByIndividual implements OnInit {

  constructor(
    private router: Router,
    public crudutils:CrudService,
    public authservice:AuthService,
    public messageService: MessageService,
    public dialog: MatDialog,
    public shared: SharedService
    ) { }
  @Input('id') id:any;

  ngOnInit() {
    this.getOrg(this.id);
  }

  organization:any;
  getOrg(id){
    this.crudutils.commonURL("OrgnaizationName_byIndividual/"+id,"GET").then((response:any)=>{
      // console.log("org response",response);
      if(response){
        this.organization=response;
      }
    });
  }
}

@Component({
  selector: 'app-user-name',
  templateUrl: './user_name.html',
  styleUrls: ['./tools.component.css']
})
export class GetUserName implements OnInit {

  constructor(
    private router: Router,
    public crudutils:CrudService,
    public authservice:AuthService,
    public messageService: MessageService,
    public dialog: MatDialog,
    public shared: SharedService
    ) { }
  @Input('id') id:any;

  ngOnInit() {
    this.getUserName(this.id)
  }

  userName:any;
  getUserName(id){
    this.crudutils.commonURL("User/Display_name/"+id,"GET").then((response:any)=>{
      if(response){
        this.userName=response;
      }
    });
  }
}

/**
*Component that Fetches Data From a API URL and creates a droplist and displays the value
*/
@Component({
  selector: 'app-fetcher-value',
  templateUrl: "./fetchervalue.html",
  styleUrls: ['./tools.component.css']
})
export class FetcherValue implements OnInit {

  constructor(
    public shared: SharedService,
    public crudutils: CrudService,
    ) { }

  @Input() url:any;
  @Input() object:any;
  @Input() config:any;
  ngOnInit() {
    this.loadURLValues();
  }
  list:any;
  output:any;

  loadURLValues(){
    var that=this;
    // console.log("object",this.object,"field",this.config,"url",this.url,"field_name",this.config.name);
    // console.log("that.object[that.config['ref_field']",that.object[that.config['ref_field']]);
    if(that.config && that.config['ref_field'] && that.config['display_field'] && that.object[that.config['name']]!=undefined && that.object[that.config['name']]!=null){
      // console.log("that.object[that.config['ref_field']",that.object[that.config['ref_field']]);
      var hit_url=this.url;
      if(hit_url.indexOf('#ref')==-1){
        hit_url+=that.object[that.config['name']];
      }else{
        hit_url=hit_url.toString().replace('#ref',that.object[that.config['name']]);
      }
      var shared_var=hit_url;
      this.crudutils.getFromCache(hit_url,"GET",shared_var).then((response:any)=>{
        // console.log("response",response,"field",this.config);
        if(response ){
          if(!Array.isArray(response)){
            response=[response];
          }
          response=_.sortBy(response,that.config['ref_field']);
          var matcher=_.find(response,function(o){
            return o[that.config['ref_field']]==that.object[that.config['name']];
          });
          if(!matcher){
            matcher=_.find(response,function(o){
              return o[that.config['name']]==that.object[that.config['name']];
            });
          }
          // console.log("matcher",matcher);
          if(matcher){
            this.output=matcher[that.config['display_field']]
          }
        }
      });
    }
    else{
      // console.log("that.object[that.config['ref_field']",that.object[that.config['ref_field']]);
      this.output="-";
    }
  }

}

@Component({
  selector: 'app-html-text-display',
  templateUrl: "./htmltextDisplay.html",
  styleUrls: ['./tools.component.css']
})

export class HTMLTextDisplay {
  constructor(){}
  @Input() htmltext:any;
  ngOnInit() {
    // console.log("htmltext:",this.htmltext);
  }
}

/**
*Component that Displays the form field value based on its configuration
*/
@Component({
  selector: 'app-form-field-value',
  templateUrl: "./formFieldValue.html",
  styleUrls: ['./tools.component.css']
})
export class FormFieldValue implements OnInit {

  constructor(
    public shared: SharedService,
    public crudutils: CrudService,
    ) { }

  @Input() object:any;
  @Input() config:any;
  @Input() module:string;
  fileBaseURL:string=this.shared.ApiEndpoint['documents_blob_holder'];

  ngOnInit() {
    // console.log("form-field");
  }
  
}
@Pipe({ name: 'noSanitize' })
export class NoSanitizePipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {

  }
  transform(html: string): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }
}


/**
*Component that Parse JSON Object to Date format
*/
@Component({
  selector: 'app-dateparse',
  template: "<span *ngIf='dateonly'>{{parseddate}}</span><span *ngIf='!dateonly'>{{parseddate}}</span>",
  styleUrls: ['./tools.component.css']
})
export class DateparseComponent implements OnInit {

  constructor(
    public shared: SharedService,
    public crudutils: CrudService,
    ) { }
  @Input('date') datedata: any;
  @Input('dateonly') dateonly:any;
  parseddate:any;
  ngOnInit() {
    // console.log(new Date(this.datedata));
    if(this.datedata){
      var format='MMMM Do YYYY, h:mm a';
      if(this.dateonly){
        format='MMMM Do YYYY';
      }
      this.parseddate=this.shared.setUTCDateFormat(this.datedata,format);
    }
  }
}


@Pipe({
  name: 'selectsearch'
})
export class SelectSearchPipe implements PipeTransform {

  transform(list: any[], filterText: string): any {
    //console.log("list",list);
    if(filterText && filterText.length){
      return list ? list.filter(item => item.display && item.display.search(new RegExp(filterText, 'i')) > -1) : [];
    }else{
      return list;
    }
  }

}
