import { Component, OnInit,Inject,ViewEncapsulation } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import * as _ from 'lodash';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {Subscription} from 'rxjs';

import {CrudService} from '../crud.service';
import {SharedService} from "../shared.service";
import {MessageService} from '../message.service';
import { MatStepper } from '@angular/material/stepper';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
	selector: 'app-document-previwe',
	templateUrl: './document-previwe.component.html',
	styleUrls: ['./document-previwe.component.scss']
})
export class DocumentPreviweComponent implements OnInit {

	constructor(
		private router: Router,
		private activeRoute: ActivatedRoute,
		private crudutils: CrudService,
		public shared: SharedService,
		public dialog: MatDialog,
		public messageService: MessageService,
		private _formBuilder: FormBuilder
		) { }

	routeParams:any;
	id:any;
	ngOnInit() {
		this.routeParams = this.activeRoute.snapshot.params;
		if (this.routeParams) {
			window.scrollTo(0,0);
			this.activeRoute.params.subscribe(params => {
				this.routeParams = params;
				this.id=params.id;
				this.getConfig();
				this.getDocument(params.id);
			});
		}
	}

	config:any;locField:any;typeField:any;equipmentTypeField:any;
	organizationField:any;individualField:any;eventField:any;brandField:any;
	equipment_floor_field:any;equipment_ball_field:any;equipment_table_field:any;equipment_net_field:any;
	equipment_larc_field:any;created_by_field:any;last_modifiedby_field:any;
	getConfig(){
		this.config = this.shared.configs["documents"];
		this.locField=this.config.loc_field;
		this.typeField=this.config.type_field;
		this.equipmentTypeField=this.config.equipmenttype_field;		
		this.organizationField=this.config.organization_field;
		this.individualField=this.config.individual_field;
		this.eventField=this.config.event_field;
		this.brandField=this.config.brand_field;
		this.equipment_ball_field=this.config.equipment_ball_field;
		this.equipment_table_field=this.config.equipment_table_field;
		this.equipment_net_field=this.config.equipment_net_field;
		this.equipment_floor_field=this.config.equipment_floor_field;
		this.equipment_larc_field=this.config.equipment_larc_field;
		this.created_by_field=this.config.created_by_field;
		this.last_modifiedby_field=this.config.last_modifiedby_field;
	}

	fileBaseURL:string=this.shared.ApiEndpoint['documents_blob_holder'];
	document:any;
	getDocument(id){
		this.messageService.showLoader(true);
		this.crudutils.commonURL("Documents/"+id,"GET").then((response:any)=>{
			console.log("doc res",response);
			this.messageService.showLoader(false);
			if(response){
				this.document=response[0];
			}
		});
	}
	
	goto(url){
		this.router.navigateByUrl(url);
	}

	back(){
		this.goto('documents');
	}
  copyLinkToClipboard(val:string){
     let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.shared.showAlert("","Link copied to clipboard");
}

}
