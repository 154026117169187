import { Component, Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {Location} from '@angular/common';
import { InitappService } from './initapp.service';
import { MessageService } from './message.service';
import * as _ from 'lodash';
import * as moment from 'moment';

/**
* All application database and other connection related configuration to be specified here.
*
* Resides Global variables for the entire application
*/
@Injectable({
  providedIn: 'root'
})

export class SharedService {

  //  ****************** CONFIGS AND VARIABLES ************************
  public ApiEndpoint: any = {
    local_host_url: 'https://ittf-admin-api.azurewebsites.net/',
    //local_host_url: 'http://localhost:56892/',
    //local_host_url: 'http://localhost:8080/',
    common_domain_url: 'api/',
    addtional_required_fields: '&additional_required_fields=',
    models_url:'assets/models/',
    //local_root:'https://stittfadmin.blob.core.windows.net/websitefiles/assets/',
    /*Masking URL - Use in Case of CDN*/
    local_root:'https://documentstore.ittf.com/websitefiles/assets/',

    //documents_blob_holder:'https://stittfadmin.blob.core.windows.net/documents/'
    /*Masking URL - Use in Case of CDN*/
    documents_blob_holder:'https://documentstore.ittf.com/documents/',

    //Admin app connect url
    //admin_app_login_url:"http://localhost:4201/#/login?goto_docs=true",
    admin_app_login_url:"https://adminportal.ittf.com/#/login?goto_docs=true"
  };

  public AzureContainers:any={
    "documents":{   
      sas: '?st=2020-01-26T15%3A30%3A21Z&se=2039-02-08T15%3A30%3A00Z&sp=racwdl&sv=2018-03-28&sr=c&sig=O5uzBSFr75E1mwajtOVjaOZlq4ug4ZwMDjzwuoVKRTo%3D',
      storageAccount: 'apphubittfstorage',
      containerName: 'documents'      
    },
    "profile_photos":{   
      sas: '?st=2020-01-30T08%3A29%3A44Z&se=2030-06-11T08%3A29%3A00Z&sp=racwdl&sv=2018-03-28&sr=c&sig=KjHl63pgoBjY4BFrjeHlM96RMnLRsD%2FPjqoZgQk09wY%3D',
      storageAccount: 'apphubittfstorage',
      containerName: 'websitefiles/assets/profile_photos'      
    }

  };
  
  public data: any = {};
  public configs: any = window['configs'];
  public roles: any = window['roles'];
  public response_messages: any = window['response_messages']; 
  public left_menu: any = window['left_menu']; 
  public app_loads: any={};
  public route_animate_delay:any=0;
  public static_config={"ittf_org_name":"ITTF HQ","ittf_org_id":227};
  public app_key:string="ittf";
  public window_data:any={mobile_width:992,current_width:window.innerWidth};
  public file_upload_params={
    sas: 'https://apphubittfstorage.blob.core.windows.net/documents?st=2020-01-14T15%3A29%3A02Z&se=2020-01-15T15%3A29%3A02Z&sp=racwdl&sv=2018-03-28&sr=c&sig=Nu4%2FlhOt5XeBnKcQCtGH8qNHkv5YKPpI0Pk2vWj6Ick%3D',
    storageAccount: 'apphubittfstorage',
    containerName: 'documents'
  }
  constructor
  (
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public location:Location,
    public initapp:InitappService,
    private messageService:MessageService
    ) { }

  init(){   
    return new Promise((resolve, reject) => { 
      if(localStorage.login){
        var that=this;       
        this.initapp.performCRUDOperations(this.ApiEndpoint.local_host_url+this.ApiEndpoint.common_domain_url+"ProfileTypes", "GET").then((response:any)=>{
          if(response){
            var allowed_values=[];
            _.each(response,function(obj){
              allowed_values.push({name:obj.ProfileTypeId,display:obj.ProfileType1});
            });
            this.data['profile_types']=allowed_values;           
          }
          resolve([]);
        });
      }else{
        resolve([]);
      }
    });
  }


  setActiveModule(module){
    this.data['active_module']=module;
    localStorage['active_module']=module;
    this.messageService.setActiveModule(module);
  }

  setUTCDateFormat(rec_date,format){
    return moment(moment.utc(rec_date)).local().format(format);
  }

  goBack(){
    this.location.back();
  }

  moveToElement(ele_id){
    var ele = document.querySelector('#'+ele_id);
    if(ele){
      window.scrollTo(ele.parentElement.offsetLeft,ele.parentElement.offsetTop-(ele.getBoundingClientRect().height*2));
    }
  }

  // ********************* ALERTS ************************

  openSnackBar(message: string, duration,title?) {
    if(title){
      message=title.toString().toUpperCase()+"\n\n"+message;
    }
    this.snackBar.open(message, "Close", {
      duration: duration * 1000,
      verticalPosition: 'top',
      horizontalPosition:'center'
    });
  }

  title: string;
  message: string;
  showAlert(title: string, message: string): void {
    this.openSnackBar(message,3,title);
    this.message = message;
    this.title = title;
    // if (title && title.length) {
      //   const dialogRef = this.dialog.open(alertDialog, {
        //     width: '500px',
        //     data: { title: this.title, message: this.message }
        //   });

        //   dialogRef.afterClosed().subscribe(result => {
          //   });

          // }
        }

        closeDialog() {
          this.dialog.closeAll();
        }

        // ************** CUSTOM FUNCTIONS *********************
        filterNumMinMaxArray(in_arr,min,max){
          return _.filter(in_arr,function(num){
            return (num>=min && num<=max);
          });
        }

        listDivisors(n) {
          if (n < 1)
            throw "Argument error";
          var small = [];
          var large = [];
          var end = Math.floor(Math.sqrt(n));
          for (var i = 1; i <= end; i++) {
            if (n % i == 0) {
              small.push(i);
              if (i * i != n) 
                // Don't include a square root twice
              large.push(n / i);
            }
          }
          large.reverse();
          return small.concat(large);
        }

        isContainsDuplicates(in_arr){
          var return_value=false;
          if(Array.isArray(in_arr) && in_arr.length>0){
            if(_.uniq(in_arr).length!==in_arr.length){
              return_value=true; //contains duplicates
            }
          }
          return return_value;
        }

        generateRandomNumber(min,max){
          var generated_number=_.random(min,max,false);
          return generated_number;
        }

        hasWhiteSpace(s) {
          return /\s/g.test(s);
        }
        setData(data, variablename) {
          this.data[variablename] = data;
        }

        replaceAll(data, find, replace) {
          var findAll = new RegExp(find, 'g');
          return data.replace(findAll, replace);
        }

        guid() {
          function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
          }
          return s4() + '-' + s4();
        }

        password() {
          function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
          }
          return s4() + s4();
        }

        random() {
          function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
          }
          return s4();
        }

        addDateTime(date, month, days, hours, minutes, seconds) {
          var result = new Date(date);
          result.setDate(result.getDate() + days);
          result.setMonth(result.getMonth() + month);
          result.setHours(result.getHours() + hours);
          result.setMinutes(result.getMinutes() + minutes);
          result.setSeconds(result.getSeconds() + seconds);
          // var x = result.getFullYear() + "-" + result.getMonth() + "-" + result.getDate() + " " + result.getHours() + ":" + result.getMinutes() + ":" + result.getSeconds();
          return result;
        }

        setTime(date,hours, minutes, seconds) {
          var result = new Date(date);
          result.setDate(result.getDate());
          result.setHours(hours);
          result.setMinutes(minutes);
          result.setSeconds(seconds);
          // var x = result.getFullYear() + "-" + result.getMonth() + "-" + result.getDate() + " " + result.getHours() + ":" + result.getMinutes() + ":" + result.getSeconds();
          return result;
        }

        accessProcess(carry_data,perm_obj){
          for(var key in perm_obj){
            if(key=='tabs'){
              this.tabsProcess(carry_data.access,perm_obj[key]);
            }else{
              carry_data.access[key]=perm_obj[key];
            }
          }
        }

        tabsProcess(carry_data,tabs){
          _.each(tabs,function(tab){      
            if(carry_data['tabs']){
              var filter_tab=_.filter(carry_data['tabs'],{name:tab.name})[0];
              if(filter_tab){          
                var index=carry_data['tabs'].indexOf(filter_tab);
                for(var key in tab){
                  if(carry_data['tabs'][index][key]){

                  }else{
                    carry_data['tabs'][index][key]=tab[key];
                  }            
                }
              }else{
                carry_data['tabs'].push(tab);
              }
            }else{
              carry_data['tabs']=[];
              carry_data['tabs'].push(tab);
            }
          });
        }

      }

      // *********** INFO ALERT MODAL CONTENT ********************
      @Component({
        selector: '',
        templateUrl: './tools/alert.component.html',
      })

      export class alertDialog {

        constructor(

          public dialogRef: MatDialogRef<alertDialog>,
          @Inject(MAT_DIALOG_DATA) public data: any
          ) { }

        onNoClick(): void {
          this.dialogRef.close();
        }

      }
