import { Component, OnInit } from '@angular/core';
import { Router ,RouterOutlet,ActivatedRoute} from '@angular/router';
import {SharedService} from "../shared.service";
import {MsAdalAngular6Service} from "microsoft-adal-angular6";
import * as _ from 'lodash';
import {CrudService} from '../crud.service';
import {AuthService} from '../authservice.service';



/**
*Component - Login
*/
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private router: Router,
    public shared: SharedService,
    public adalSvc:MsAdalAngular6Service,
    private crudutils: CrudService,
    private authservice: AuthService,

    ) { 
    console.log(this.adalSvc.userInfo);

  }

/**  
* It Holds User data which they are entering. Here default values are set
*/
userDetails:any={username:"admin",password:"admin"};
localStorage:any=localStorage;

ngOnInit() {
  //this.adalSvc.login();

  if(localStorage['adal.state.login'] && !localStorage.login){
    var loggedInAdalUsername=this.adalSvc.LoggedInUserEmail;
    var ad_user={username:loggedInAdalUsername,ad_auth:1,password:'microsoft_ad_user'};
    this.authservice.login(ad_user).then((response:any)=>{
      if(response){
        localStorage.login=true;// Before reloading we need to get auth token for the AD Login User 
        window.location.reload();
      }
      else{
        localStorage.removeItem('adal.state.login');
        this.shared.showAlert("Invalid User","Please contact your system administrator to get access to this application");
        this.crudutils.clearAuthVariables();
        setTimeout(()=>{this.adalSvc.logout();},2000);
        
      }
    });    
  }

  if (localStorage.login) {
    localStorage['active_module']="dashboard";
    var that=this;
    setTimeout(function(){
      that.router.navigate(['/dashboard']);
    },500);      
  }
}


loginWithAD(){
  this.crudutils.clearAuthVariables()
  this.adalSvc.login();
}


/**  
* Handles Login
*/
login(user_data){

  if(user_data && user_data.username && user_data.username.length && user_data.password && user_data.password.length){
    // localStorage.login=true;
    // localStorage.user_data=JSON.stringify(user_data);

    this.authservice.login(user_data).then((response:any)=>{
      if(response){
        window.location.reload();
      }
    });

    // window.location.reload();
  }else{
    this.shared.openSnackBar("Enter Username and Password",3);
  }
}

}