import { Component, OnInit, Output, EventEmitter,ElementRef,ViewChild,Inject } from '@angular/core';
import { Router ,RouterOutlet,ActivatedRoute} from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {MatMenuModule} from '@angular/material/menu';
import {Subscription} from 'rxjs';
import * as _ from 'lodash';
import {CrudService} from './crud.service';
import {SharedService} from "./shared.service";
import {MessageService} from './message.service';
import {AuthService} from './authservice.service';
// import {notificationComponent} from './tools/tools.component';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  showMainContent: Boolean = false;
  profileContent: Boolean = false;
  title = 'Documents';
  proggressBarSubscription:Subscription;
  activeModuleSubscription:Subscription;
  progressbar:boolean;

  @Output() public sidenavToggle = new EventEmitter();
  @Output() sidenavClose = new EventEmitter();
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private crudutils: CrudService,
    public shared: SharedService,
    public messageService: MessageService,
    private authService: AuthService,
    public dialog: MatDialog,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    // public tools:notificationComponent
    ) {
    this.proggressBarSubscription = this.messageService.loaderListener().subscribe(message => {
      var that=this;
      setTimeout(function(){
        that.progressbar = message;
      });        
    });

    this.activeModuleSubscription = this.messageService.activeModuleListener().subscribe(message => {
      this.activeModule=message;
    });   

  }

  menu:any;
  isLoggedIn:boolean;activeModule:string;storage:any=localStorage;role_data:any;
  roleDisplay:string="";
  ngOnInit(){    
  }

  adminAppLogin(){
    window.open(this.shared.ApiEndpoint.admin_app_login_url);
  }


  @ViewChild("left_pane",{static:false}) left_pane:ElementRef;
  @ViewChild("right_pane",{static:false}) right_pane:ElementRef;
  
  toggleMobileLeftPane(event:any){
    var flag=this.left_pane.nativeElement.getAttribute("force-open");
    if(flag && flag.toString()=='true'){
      this.hideMobileLeftPane(event);
    }
    else{
      this.showMobileLeftPane(event);
    }
  }

  showMobileLeftPane(event:any){
    this.left_pane.nativeElement.setAttribute("force-open",true);
    this.right_pane.nativeElement.setAttribute("menu-open",true);

  }

  hideMobileLeftPane(event:any){
    this.left_pane.nativeElement.setAttribute("force-open",false);
    this.right_pane.nativeElement.setAttribute("menu-open",false);
  }

  settingsList() {
    this.showMainContent = this.showMainContent ? false : true;
    this.profileContent = this.profileContent ? false : false;
    transform: 'translateX(-100%)';
    animate('0.5s 300ms ease-in');
  }
  profileList() {
    this.profileContent = this.profileContent ? false : true;
    this.showMainContent = this.showMainContent ? false : false;
  }
  settingsClose(){
    this.showMainContent = this.showMainContent ? false : false;
    
    animate('0.3s ease-out', style({ transform: 'translateX(100%)' }));
  }
  profileClose(){
    this.profileContent = this.profileContent ? false : false;
  }
  
}

