import {  Injectable } from '@angular/core';
import * as _ from 'lodash';

import {SharedService} from './shared.service';


/**
 * @ignore
 */
 
 @Injectable({
     providedIn: 'root'
 })

 export class ValidationService {

     constructor(
         public shared: SharedService
         )
     { 
     }


     organization(info,type){
         switch (type) {
             case "basic":
             // code...
             break;
             
             default:
             // code...
             break;
         }
     }



     signUp(obj) {
         var iChars = "!@#$%^&*()+=-[]\\\';,./{}|\":<>?";
         var passwordFormat = "(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z])";
         var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
         var err = "";
         if (obj.data.first_name == undefined || obj.data.first_name.length == 0) {
             return err = "First name cannot be Empty";
         }
         if (obj.data.email == undefined || obj.data.email.length == 0) {
             return err = "Email id cannot be Empty";
         }
         if (!obj.data.email.match(mailformat)) {
             return err = "Incorrect mail format";
         }
         if (obj.data.password == undefined || obj.data.password.length == 0) {
             return err = "Password cannot be Empty";
         }

         if (obj.data.confirm_password == undefined || obj.data.confirm_password.length == 0) {
             return err = "Confirm Password cannot be Empty";
         }

         if (obj.data.password && (obj.data.password.length < 6 || obj.data.password.length > 20)) {
             return err = "Passwords must be between 6 and 20 characters";
         }

         if (obj.data.password != obj.data.confirm_password) {
             return err = "Password mismatch";
         }
         if (obj.data.phone == undefined || obj.data.phone.length == 0) {
             return err = "Mobile number cannot be Empty";
         }
         if (obj.data.phone && this.shared.hasWhiteSpace(obj.data.phone)) {
             return err = "Mobile number should not contain any spaces";
         }
         // console.log("obj.data.phone.length",obj.data.phone.length);
         if (obj.data.phone && obj.data.phone.length != 10) {
             return err = "Mobile number should be ten digit";
         }
         if (obj.data.phone && obj.data.phone.length == 10 && isNaN(obj.data.phone)) {
             return err = "Mobile number should be number";
         }    
         return true;
     }

     changePassword (currentData, oldData) {
         var err="";
         if ((currentData.data.old_password == undefined || currentData.data.old_password.length == 0) || (currentData.data.new_password == undefined || currentData.data.new_password.length == 0)) {
             return err = "Password cannot be Empty";
         }

         if (currentData.data.confirm_password == undefined || currentData.data.confirm_password.length == 0) {            
             return err = "Confirm Password cannot be Empty";
         }

         if (currentData.data.old_password != oldData.data.password) {
             return err = "Old password mismatch";
         }

         if (currentData.data.new_password && (currentData.data.new_password.length < 6 || currentData.data.new_password.length > 20)) {
             return err = "Passwords must be between 6 and 20 characters";
         }

         if (currentData.data.new_password != currentData.data.confirm_password) {
             return err = "New password and Confirm password mismatch";
         }
         return true;
     }

     updateProfile(obj) {
         var iChars = "!@#$%^&*()+=-[]\\\';,./{}|\":<>?";
         var passwordFormat = "(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z])";
         var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
         var err = "";
         if (obj.data.first_name == undefined || obj.data.first_name.length == 0) {
             return err = "First name cannot be Empty";
         }
         if (obj.data.email == undefined || obj.data.email.length == 0) {
             return err = "Email id cannot be Empty";
         }
         if (!obj.data.email.match(mailformat)) {
             return err = "Incorrect mail format";
         }
         if (obj.data.phone == undefined || obj.data.phone.length == 0) {
             return err = "Mobile number cannot be Empty";
         }
         if (obj.data.phone && obj.data.phone.length == 10 && isNaN(obj.data.phone)) {
             return err = "Mobile number should be number";
         }
         if (obj.data.phone && this.shared.hasWhiteSpace(obj.data.phone)) {
             return err = "Mobile number should not contain any spaces";
         }
         if (obj.data.phone && obj.data.phone.length != 10) {
             return err = "Mobile number should be ten digit";
         }
         // if (obj.data.birthday == undefined) {
             //     return err = "Birth Date cannot be empty";
             // }
             return true;
         }

         updateAddress(obj) {
             var iChars = "!@#$%^&*()+=-[]\\\';,./{}|\":<>?";
             var passwordFormat = "(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z])";
             var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
             var err = "";

             if (obj.data.name == undefined || obj.data.name.length == 0) {
                 return err = "Name cannot be Empty";
             }
             if (obj.data.phone_number == undefined || obj.data.phone_number.length == 0) {
                 return err = "Mobile number cannot be Empty";
             }
             if (obj.data.phone_number && obj.data.phone_number.length == 10 && isNaN(obj.data.phone_number)) {
                 return err = "Mobile number should be number";
             }
             if (obj.data.phone_number && this.shared.hasWhiteSpace(obj.data.phone_number)) {
                 return err = "Mobile number should not contain any spaces";
             }
             if (obj.data.phone_number && obj.data.phone_number.length != 10) {
                 return err = "Mobile number should be ten digit";
             }
             if (obj.data.email != undefined && obj.data.email.length == 0) {
                 return err = "Email id cannot be Empty";
             }
             if (obj.data.email != undefined && !obj.data.email.match(mailformat)) {
                 return err = "Incorrect mail format";
             }
             if (obj.data.address == undefined || obj.data.address.length == 0) {
                 return err = "Address cannot be Empty";
             }
             if (obj.data.city == undefined || obj.data.city.length == 0) {
                 return err = "District cannot be Empty";
             }
             if (obj.data.state == undefined || obj.data.state.length == 0) {
                 return err = "State cannot be Empty";
             }
             if (obj.data.pincode && this.shared.hasWhiteSpace(obj.data.pincode)) {
                 return err = "Pincode should not contain any spaces";
             }
             if (obj.data.pincode == undefined || obj.data.pincode.length == 0) {
                 return err = "Pincode cannot be Empty";
             }
             if (obj.data.pincode != undefined && obj.data.pincode.length > 0 && isNaN(obj.data.pincode)) {
                 return err = "Pincode must be number";
             }
             if (obj.data.alternative_phone_number && obj.data.alternative_phone_number.length != 10) {
                 return err = "Alternative mobile number should be ten digit";
             }
             if (obj.data.alternative_phone_number && obj.data.alternative_phone_number.length == 10 && isNaN(obj.data.alternative_phone_number)) {
                 return err = "Alternative mobile number should be number";
             }

             return true;
         }
         removeEmptyAddress(addressObj) {
             var err = "";
             var i, l = addressObj.length, index = [];
             for (i = 0; i < l; i++) {
                 var obj = addressObj[i], data = [];
                 if (obj) {
                     if (obj.name == undefined || obj.name.length == 0) {
                         index.push(i);
                         continue;
                     }
                     if (obj.phone_number == undefined || obj.phone_number.length == 0) {
                         index.push(i);
                         continue;
                     }
                     if (obj.phone_number && obj.phone_number.length != 10) {
                         index.push(i);
                         continue;
                     }
                     if (obj.phone_number && obj.phone_number.length == 10 && isNaN(obj.phone_number)) {
                         index.push(i);
                         continue;
                     }
                     if (obj.address == undefined || obj.address.length == 0) {
                         index.push(i);
                         continue;
                     }
                     if (obj.city == undefined || obj.city.length == 0) {
                         index.push(i);
                         continue;
                     }
                     if (obj.state == undefined || obj.state.length == 0) {
                         index.push(i);
                         continue;
                     }
                     if (obj.pincode == undefined || obj.pincode.length == 0) {
                         index.push(i);
                         continue;
                     }
                     if (obj.alternative_phone_number && obj.alternative_phone_number.length != 10) {
                         index.push(i);
                         continue;
                     }
                     if (obj.alternative_phone_number && obj.alternative_phone_number.length == 10 && isNaN(obj.alternative_phone_number)) {
                         index.push(i);
                         continue;
                     }
                 } else {
                     index.push(i);
                     continue;
                 }
             }
             var x = addressObj;
             for (var j = 0; j < index.length; j++) {
                 x = _.without(x, addressObj[index[j]]);
             }
             return x;
         } 

         phoneNumber(number) {
             var err;
             if (number == undefined || number.length == 0) {
                 return err = "Mobile number cannot be Empty";
             }
             if (number && this.shared.hasWhiteSpace(number)) {
                 return err = "Phone number should not contain any spaces";
             }
             if (number && number.length != 10) {
                 return err = "Mobile number should be ten digit";
             }
             if (number && number.length == 10 && isNaN(number)) {
                 return err = "Mobile number should be number";
             }
             return true;
         }

         enquiry(obj) {
             var iChars = "!@#$%^&*()+=-[]\\\';,./{}|\":<>?";
             var passwordFormat = "(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z])";
             var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
             var err = "";
             if (obj.data.name == undefined || obj.data.name.length == 0) {
                 return err = "Name cannot be Empty";
             }
             if (obj.data.email == undefined || obj.data.email.length == 0) {
                 return err = "Email cannot be Empty";
             }
             if (obj.data.comment == undefined || obj.data.comment.length == 0) {
                 return err = "Comment cannot be Empty";
             }
             if (obj.data.city == undefined || obj.data.city.length == 0) {
                 return err = "City cannot be Empty";
             }
             if (obj.data.state == undefined || obj.data.state.length == 0) {
                 return err = "State cannot be Empty";
             }
             return true;
         }
     }