import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { Router ,ActivatedRoute} from '@angular/router';


import { DbactService } from './dbact.service';
import { SharedService } from './shared.service';
import { MessageService } from './message.service';

/**
  *Internal method for computing and transforming HTTP CRUD data
  */

  @Injectable({
    providedIn: 'root'
  })

  export class CrudService {

    constructor(
      private http: HttpClient,
      private dbact: DbactService,
      public shared: SharedService,
      public messageService: MessageService,
      public _router:Router

      ) { }
    response_messages = this.shared.response_messages;
    updateFields(data, docID, url, method) {
      return new Promise((resolve, reject) => {
        if (data) {
          var postObject = new Object();
          postObject["data"] = new Object();
          postObject['data'] = data;
          this.commonURL(url + docID + "/updatefields", method, postObject, null, false).then((response) => {
            if (response) {
              resolve(response);
            }else{
              resolve(false);
            }

          });
        }
      });
    }

    getLocalJson(filename){
      return new Promise((resolve,reject)=>{
        this.dbact.getLocalJson(filename).subscribe((response:any)=>{       
          resolve(response);
        },error=>{
          resolve(false);
        });
      });

    }


    deleteById(module, id, rev) {
      return new Promise((resolve, reject) => {
        this.commonURL(module + "/" + id + "/" + rev, "DELETE", null, null, true).then((response) => {
          // console.log('response',response);
          if(response){
            resolve(response);
          }else{
            resolve(false);
          }
        });
      })
    }    

    getFromCache(url, method, variablename) {
      return new Promise((resolve, reject) => {
        if (this.shared.data[variablename]) {
          resolve(this.shared.data[variablename]);
        } else {
          this.commonURL(url, method, null, false, false).then((response) => {
            if (variablename == "appGlobalEntry" && response) {
              this.shared.data[variablename] = response;
              resolve(response);
            } else {
              if (response) {
                this.shared.data[variablename] = response;
                resolve(response);
              } else {
                resolve(false);
              }
            }
          });
        }
      });
    }

    getConfig(module) {
      return new Promise((resolve, reject) => {
        if (this.shared.configs[module]) {
          this.getFromCache("configuration/" + this.shared.configs[module]._ref, "GET", module + "config").then((response: any) => {
            if (response) {
              var filter_fields = [], data = [], config;
              var fields_needed = this.shared.configs[module].fields_needed;
              var i, l = response.data.length;
              var tabindex = 0;
              for (i = 0; i < l; i++) {
                var cobj = response.data[i];
                var d_cobj = response.data[i];
                var filter = fields_needed.filter(fields_needed => fields_needed.name === cobj.name);
                if (filter && filter[0]) {
                  tabindex += 1;
                  cobj.group = filter[0].group;
                  if (filter[0].display) {
                    cobj.display = filter[0].display;
                    d_cobj.display = filter[0].display;
                  }
                  if (filter[0].min_length) {
                    cobj.min_length = filter[0].min_length;
                  }
                  if (filter[0].max_length) {
                    cobj.max_length = filter[0].max_length;
                  }
                  if (filter[0].input_value) {
                    cobj.input_value = filter[0].input_value;
                  }
                  if (filter[0].disable) {
                    cobj.disable = filter[0].disable;
                  }
                  if (filter[0].access) {
                    cobj.access = filter[0].access;
                  }
                  if (filter[0].default) {
                    cobj.default = filter[0].default;
                  }
                  if (filter[0].additional) {
                    cobj.additional = filter[0].additional;
                  }
                  if (filter[0].type) {
                    cobj.type = filter[0].type;
                  }
                  if (filter[0].allowed_values && cobj.allowed_values) {
                    var j, k = cobj.allowed_values.length, tempArr = [];
                    for (j = 0; j < k; j++) {
                      if (filter[0].allowed_values.indexOf(cobj.allowed_values[j].name) != -1) {
                        tempArr.push(cobj.allowed_values[j]);
                      }
                    }
                    cobj.allowed_values = tempArr;
                    d_cobj.allowed_values = tempArr;
                  }

                  cobj.tabindex = tabindex;
                  filter_fields.push(cobj);
                  data.push(d_cobj);
                  // console.log("cobj:",cobj,"tabindex:",tabindex);
                }
              }
              //console.log("form data",data);
              config = this.shared.configs[module];
              config.form_config = filter_fields;
              config.data = data;
              resolve(config);
            } else {
              resolve(false);
            }
          });
        } else {
          resolve(false);
        }
      });
    }

    commonURL(url, method, params?, formObject?,  custom_obj?) {
      return new Promise((resolve, reject) => {
        var paramsObject = undefined;
        if (formObject) {
          paramsObject = formObject;
        } else {
          paramsObject = params;
        }

        url = this.shared.ApiEndpoint.common_domain_url + url;

        this.dbact.performCRUDOperations(paramsObject, url, method).then((response: any) => {
          resolve(response);
        }, error => {
          var err = error;
          console.log("err", err, 'custom_obj', custom_obj);
          if (err) {
            if(!custom_obj){
              custom_obj={};
            }
            var message = err.error;
            if (err.error && err.error.message) {
              message = err.error.message;
            } else if (err.message) {
              if (err.message.indexOf("[object") != -1) {
                message = this.response_messages.error.something.msg;
              } else {
                message = err.message;
              }
            }
            if (message.indexOf('Email should be unique') != -1) {
              if(!custom_obj.dont_show_error){
                this.shared.showAlert("Error", this.response_messages.error.user_already_exist.msg);
              }
              resolve(false);
            } else if (message.indexOf("token doesnt exist") != -1) {
              this.shared.showAlert("Server Error", "Token does not exist.Login again");
              this.logout();
            } else {
              // console.log("3");
              if(!custom_obj.dont_show_error){
                this.shared.showAlert("Server Error", message);
              }
              // console.log("TO SHOW 404");
              // sessionStorage["show_404"]=true;
              // this._router.navigate(['404']);
              resolve(false);
            }
          } else {
            resolve(false);
          }
        });
      });
    }
    getDocumentDetails(docid, moduleObject,noloader?) {
      return new Promise((resolve, reject) => {
        var module = undefined;
        if (moduleObject && moduleObject.module != undefined && docid != undefined) {
          if (moduleObject.config.indexOf('use_custom_repo_') != -1) {
            module = moduleObject.config;
          } else {
            module = moduleObject._ref;
          }

          if(!noloader){
            this.messageService.showLoader(true);
          }
          if (moduleObject.config.indexOf('use_custom_repo_') != -1) {
            module = moduleObject.config;
          } else {
            module = moduleObject._ref;
          }

          this.commonURL(module + "/" + docid, "GET", null, false, false).then((response: any) => {
            if(!noloader){
              this.messageService.showLoader(false);
            }
            var doc_details = response;
            if (doc_details == undefined) {
              resolve(false);
            } else {
              resolve(doc_details);
            }
          });
        } else {
          resolve(false);
        }
      });
    }

    getById(module,id){
      return new Promise((resolve,reject)=>{
        this.commonURL(module + "/"+id,"GET",null,false,false).then((response:any)=>{
          // console.log("get docs",response);
          resolve(response);	
        });  	
      });
    }

    addDocument(module,inputObject){
      return new Promise((resolve, reject) => {
        this.formValidator(inputObject, module.form_fields, true).then((valid: boolean) => {
          if (!valid) {
            resolve(false);
            return;
          } else {
            var input=[inputObject];
            if(module.not_array){
              input=inputObject;
            }
            this.commonURL(module.post_url, "POST", null, input, true).then((response: any) => {
              if (response) {
                resolve(response);
              } else {
                resolve(false);
              }
            });
          }
        });

      });
    }

    updateDocument(module,inputObject){      
      return new Promise((resolve,reject)=>{
        this.formValidator(inputObject, module.form_fields, true).then((valid: boolean) => {
          if (!valid) {
            resolve(false);
            return;
          } else {
            var url=module.post_url;
            if(module.put_url){
              url=module.put_url;
            }
            var input=[inputObject];
            if(module.not_array){
              input=inputObject;
            }

            this.commonURL(url,"POST",null,input,true).then((response:any)=>{
              if(response){
                resolve(response);
              }else{
                resolve(false);
              }
            });
          }
        });
      });
    }
    deleteDocument(module,clearObject){
      return new Promise((resolve,reject)=>{
        var deleteObject=_.cloneDeep(deleteObject);
        this.commonURL(module + "/" + clearObject._id + "/" + clearObject._rev,"DELETE",null,null,true).then((response:any)=>{
          if (response) {
            resolve(response);
          }else{
            resolve(false);
          }
        });
      });
    }

    contentAccessCheck(id,category_id?,sub_category_id?){

      return new Promise((resolve,reject)=>{
        this.messageService.showLoader(true);
        this.getById("use_custom_repo_Levels",id).then((response:any)=>{
          this.messageService.showLoader(false);
          if (response) {
            var result:any={};
            result.content=this.shared.data[response.data.name];					
            var filter:any=_.filter(result.content.menu.category,{id:parseInt(category_id)})[0];					
            if(filter){
              result.access_content={
                category:filter.access_content
              };
            }
            if(sub_category_id){
              var filter2:any=_.filter(filter.subcategory,{id:parseInt(sub_category_id)})[0];					
              if(filter2){
                result.access_content.sub_category=filter2.access_content;
              }
            }
            resolve(result);
          }else{
            resolve(false);
          }
        });
      });
    }

    formValidator(static_object, configurationobject, throwFlag) {
      // console.log("static_object",static_object,"config",configurationobject);
      return new Promise((resolve, reject) => {
        var error = new Array();
        var emailMatchbool = false;
        var emptyCheckConditionFieldBool = false;
        var cloner = _.cloneDeep(static_object);
        var customerrMsg = "";
        _.each(configurationobject, function (object) {
          if (object['mandatory']) {
            if(object.type=='date'){
              if(static_object[object.name]=='Invalid Date')
              {
                static_object[object.name]=undefined;
              }
            }

            /*Check for Conditional Dropdown*/
            if ((object.condition_field && object.condition_value) && (static_object[object.condition_field] && static_object[object.condition_field] != object.condition_value)) {
              emptyCheckConditionFieldBool = true;
            } else {
              emptyCheckConditionFieldBool = false;
            }

            /*Check for Conditional Dropdown*/
            if (!emptyCheckConditionFieldBool && (static_object[object.name] == undefined || static_object[object.name].length <= 0 || static_object[object.name] == null || static_object[object.name] == "null")) {
              error.push(object.display);
            }
          }

          if (object.name == "email") {
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (static_object && static_object && static_object[object.name] && !(static_object[object.name].match(mailformat))) {
              emailMatchbool = true;
              error.push(object.display);
            }
          }

          if (object['min_length'] && cloner.data[object.name]) {
            if (cloner.data[object.name].length < object['min_length']) {
              customerrMsg += object['display'] + " should be minimum " + object['min_length'] + " digits";
            }
          }

          if (object['max_length'] && cloner.data[object.name]) {
            if (cloner.data[object.name].length > object['max_length']) {
              customerrMsg += object['display'] + " should be maximum " + object['max_length'] + " digits";
            }
          }

          if (object['input_value'] && cloner.data[object.name]) {
            switch (object['input_value']) {
              case 'number':
              if (isNaN(cloner.data[object.name])) {
                customerrMsg += object['display'] + " should be number";
              }
              break;
            }
          }
        });

        if (error.length > 0 || customerrMsg.length > 0) {
          var errmsg = " cannot be empty";
          if (emailMatchbool && error[0] == "Email") {
            errmsg = " is not specified in the correct format";
          }
          if (customerrMsg) {
            this.shared.showAlert("Error", customerrMsg);
          } else if (!throwFlag) {
            this.shared.showAlert("Mandatory Fields Required", this.makeErrorString(error) + errmsg);
          } else {
            this.shared.showAlert("Mandatory Fields Required", this.makeErrorString(error) + errmsg);
          }
          resolve(false);
        } else {
          static_object = _.cloneDeep(cloner);
          resolve(true);
        }
      });
    }

    makeErrorString(error){
      if(Array.isArray(error)){
        var err_string="";
        _.each(error,function(obj,index){
          if(index!=0){
            err_string+=", ";//comma followed by space is important
          }
          err_string+=obj;         
        });
        return err_string;
      }
      else{
        return error;
      }
    }

    tabsAccessByRole(tabs,type,module?) {
      var role_data=JSON.parse(localStorage.role_data);
      console.log("role_data",role_data);
      if(role_data.names.indexOf('Super Admin')!=-1 || role_data.names.indexOf('Admininstrator')!=-1){
        return tabs;
      }else{
        if(role_data.permissions){
          var returnTabs=[],permissions=role_data.permissions;
          if(permissions){
            switch (type) {
              case 'left_menu':
              _.each(tabs,function(tab){
                if(permissions[tab.name]){
                  returnTabs.push(tab);
                }
              });
              break;
              case 'inner_view_tabs':
              permissions=role_data.permissions[module].access.tabs;
              _.each(tabs,function(tab){
                var filter=_.filter(permissions,{'name':tab.name})[0];
                if((filter && filter.view) ||  tab.name=="basic" || tab.name=="basic_information" ){
                  returnTabs.push(tab);
                }
              });
              break;
            }
          }
          return returnTabs;
        }else{
          return tabs;
        }
      }
    }

    operationAccessByRole(op,module) {
      var role_data=JSON.parse(localStorage.role_data);
      if(role_data.names.indexOf('Super Admin')!=-1 || role_data.names.indexOf('Admininstrator')!=-1){
        return true;
      }else{
        if(role_data.permissions){
          var permissions=role_data.permissions;
          if(permissions[module] && permissions[module].access && permissions[module].access[op]){
            return true;
          }else{
            return false;
          }
        }else{
          return true;
        }
      }
    }

    tabOperationAccessByRole(op,module,tab) {
      var role_data=JSON.parse(localStorage.role_data);
      if(role_data.names.indexOf('Super Admin')!=-1 || role_data.names.indexOf('Admininstrator')!=-1){
        return true;
      }else{
        if(role_data.permissions){
          var permissions=role_data.permissions;
          var filter=_.filter(permissions[module].access.tabs,{'name':tab})[0];
          if(filter && filter[op]){
            return true;
          }else{
            return false;
          }
        }else{
          return true;
        }
      }
    }

    setOrgObject(org,edit_mode?) {
      var result={
        "basic_information": {
          "OrganizationName": org['basic_information']['OrganizationName'],
          "OrganizationShortName": org['basic_information']['OrganizationShortName'],
          "address1": org['basic_information']['address1'],
          "address2": org['basic_information']['address2'],
          "address3": org['basic_information']['address3'],
          "City": org['basic_information']['City'],
          "Postcode": org['basic_information']['Postcode'],
          "OrganizationTypeId":org['basic_information']['OrganizationTypeId'],
          "CountryCode":org['basic_information']['CountryCode'],       
          "CurrentOrganizationHead":org['basic_information']['CurrentOrganizationHead'],
          "CurrenttOrganizationHeadProfileTypeID": org['basic_information']['CurrenttOrganizationHeadProfileTypeID'],
          "CurrentOrganization_Second_in_Line": org['basic_information']['CurrentOrganization_Second_in_Line'],
          "CurrentOrganization_Second_in_LineProfileTypeId": org['basic_information']['CurrentOrganization_Second_in_LineProfileTypeId'],
          "ContactPerson": org['basic_information']['CurrentOrganizationHead'],
          "Website": org['basic_information']['Website'],
          "isActive": true,
          "Logo": org['basic_information']['Logo'],
        },
        "additional_information": {
          "JoinedYear": org['additional_information']['JoinedYear'],
          "ElectionYear":  org['additional_information']['ElectionYear'],
          "UpcomingElectionYear": org['additional_information']['UpcomingElectionYear'],
          "NumberRegisteredPlayers": org['additional_information']['NumberRegisteredPlayers'],
          "NumberAmateurPlayers": org['additional_information']['NumberAmateurPlayers'],
          "ResultsNationalChampionship": org['additional_information']['ResultsNationalChampionship'],
          "ResultsNationalYouthChampionship": org['additional_information']['ResultsNationalYouthChampionship'],
          "OrganizationId":  org['additional_information']['OrganizationId']?org['additional_information']['OrganizationId']:null,
          "OrgAdditionalInfoId":  org['additional_information']['OrgAdditionalInfoId']?org['additional_information']['OrgAdditionalInfoId']:null
        },
        "contact_information": {
          "emails": org['contact_information']['emails'],
          "contact_numbers": org['contact_information']['contact_numbers']
        },
        "document_information": org['document_information']
      }
      var country_filter=_.filter(this.shared.data['countries'],{name:org['basic_information']['CountryCode']});
      if(country_filter && country_filter[0]){
        result['basic_information']['ContinentId']=country_filter[0]['ContinentId'];
      }
      if(edit_mode){
        result["basic_information"]["OrganizationId"]=org['basic_information']['OrganizationId'];
      }
      return result;
    }

    setIndividuals(individuals,edit_mode?){
      if(individuals.basic_information.birthDate){
        // var dob = this.setBirthDate(individuals.basic_information.birthDate);
        // console.log("dob",dob);
      }
      var result={
        "basic_information": {
          "ittfid":individuals['basic_information']['ittfid']?individuals['basic_information']['ittfid']:null,
          "OrganizationId": individuals['basic_information']['OrganizationId'],
          "Gender": individuals['basic_information']['Gender'],
          "Nationality":individuals['basic_information']['Nationality'],
          "isActive": 1,
          "birthDate":individuals.basic_information.birthDate,
          "deathdate":individuals['basic_information']['deathdate'],
          "address1": individuals['basic_information']['address1'],
          "address2": individuals['basic_information']['address2'],
          "address3": individuals['basic_information']['address3'],
          "City": individuals['basic_information']['City'],
          "Residing_country_code":individuals['basic_information']['Residing_country_code'],
          "Postcode": individuals['basic_information']['Postcode'],

          "Passport_Details":individuals['basic_information']['Passport_fullname']?1:0,
          "Passport_fullname":individuals['basic_information']['Passport_fullname'],
          "passport_type":individuals['basic_information']['passport_type'],
          "passport_no":individuals['basic_information']['passport_no'],
          "passport_issue_date":individuals['basic_information']['passport_issue_date'],
          "passport_expiry_date":individuals['basic_information']['passport_expiry_date'],

          "emergency_contact_person_givenName":individuals['basic_information']['emergency_contact_person_givenName'],
          "emergency_contact_person_familyName":individuals['basic_information']['emergency_contact_person_familyName'],

          "Default_Language_Code": "en-us",
          "Status":"Active",
          "photo":individuals['basic_information']['photo']   
        },
        "translation_information":individuals['translation_information'],
        "additional_information": {
          "IndividualAdditionalInfoId":  individuals['additional_information']['IndividualAdditionalInfoId']?individuals['additional_information']['IndividualAdditionalInfoId']:null,
          "ittfid":individuals['basic_information']['ittfid']?individuals['basic_information']['ittfid']:null,
          "JoinedYear": individuals['additional_information']['JoinedYear']          
        },
        "eligibility_information": {
          "IndividualEligibilityId":individuals['eligibility_information']['IndividualEligibilityId']?individuals['eligibility_information']['IndividualEligibilityId']:null,
          "ittfid":individuals['basic_information']['ittfid']?individuals['basic_information']['ittfid']:null,
          "GrandFatherEligibility": individuals['eligibility_information']['GrandFatherEligibility'],
          "OlympicEligibility": individuals['eligibility_information']['OlympicEligibility'],
          "TeamEligibility": individuals['eligibility_information']['TeamEligibility'],
          "WorldTitleEligibility": individuals['eligibility_information']['WorldTitleEligibility'],
          "IsActive": individuals['eligibility_information']['IsActive']
        },
        "contact_information": {
          "emails": individuals['contact_information']['emails'],
          "contact_numbers": individuals['contact_information']['contact_numbers']
        },
      }

      if(individuals['profile_information']){
        _.each(individuals['profile_information'],function(obj){
          // console.log("obj",obj);
          obj['StartDate']=new Date(obj['StartDate']);
          if(obj['EndDate']){
            obj['EndDate']=new Date(obj['EndDate']);
          }
        });
        // console.log("profile",individuals['profile_information']);
        result['profile_information']=individuals['profile_information'];
      }

      return result;
    }

    setEvents(event,edit_mode?){
      var result={
        "basic_information": {
          "EventId":event['basic_information']['EventId']?event['basic_information']['EventId']:null,
          "EventName":event['basic_information']['EventName'],
          "EventType":event['basic_information']['EventType'],
          "StartDateTime": new Date(event['basic_information']['StartDateTime']),
          "EndDateTime": new Date(event['basic_information']['EndDateTime']),
          "CountryCode":event['basic_information']['CountryCode'],
          "OrganizationId":event['basic_information']['OrganizationId'],
          "address1": event['basic_information']['address1'],
          "address2": event['basic_information']['address2'],
          "address3": event['basic_information']['address3'],
          "City": event['basic_information']['City'],
          "Postcode": event['basic_information']['Postcode'],
          "Status": event['basic_information']['Status'],
          "LocationId": event['basic_information']['LocationId'],
          "ContactPerson": event['basic_information']['ContactPerson'],
          "Photo": event['basic_information']['Photo'],
          "Website": event['basic_information']['Website'],
          "isActive": true
        },
        "competition_information": {
          "EventCompetitionId":event['competition_information']['EventCompetitionId']?event['competition_information']['EventCompetitionId']:null,
          "LevelId":event['competition_information']['LevelId'],
          "Preliminary_submission_Date": new Date(event['competition_information']['Preliminary_submission_Date']),
          "Final_Submission_Date": new Date(event['competition_information']['Final_Submission_Date']),
          "EntriesRankingWeek":event['competition_information']['EntriesRankingWeek'],
          "EntriesRankingMonth":event['competition_information']['EntriesRankingMonth'],
          "EntriesRankingYear": event['competition_information']['EntriesRankingYear'],
          "RankingCountWeek": event['competition_information']['RankingCountWeek'],
          "RankingCountMonth": event['competition_information']['RankingCountMonth'],
          "RankingCountYear": event['competition_information']['RankingCountYear'],
          "AllowReserve": event['competition_information']['AllowReserve'],
          "VisaInformation": event['competition_information']['VisaInformation'],
          "Comments": event['competition_information']['Comments'],
          "Tournament_Director": event['competition_information']['Tournament_Director'],
          "OES_CompetitionManager": event['competition_information']['OES_CompetitionManager'],
          "OES_LOC": event['competition_information']['OES_LOC'],
          "Referee": event['competition_information']['Referee'],
          "Media_Officer": event['competition_information']['Media_Officer'],
          "OrganizerContactPerson": event['competition_information']['OrganizerContactPerson'],
          "PlayingSystem": event['competition_information']['PlayingSystem']
        },
        "organization_information":event["organization_information"],
        "eventsubevent_information":event["eventsubevent_information"],
        "fee_bank_account_information": {
          "EventFeeBankAccountId":event['fee_bank_account_information']['EventFeeBankAccountId']?event['fee_bank_account_information']['EventFeeBankAccountId']:null,
          "AccountNumber":event['fee_bank_account_information']['AccountNumber'],
          "BIC":event['fee_bank_account_information']['BIC'],
          "AccountName":event['fee_bank_account_information']['AccountName'],
          "IBAN": event['fee_bank_account_information']['IBAN'],
          "BankAccount": event['fee_bank_account_information']['BankAccount']
        },
        "sponsor_information":event["sponsor_information"],
        "oes_information": {
          "EventOESId":event['oes_information']['EventOESId']?event['oes_information']['EventOESId']:null,          
          "CurrencyId":event['oes_information']['CurrencyId'],
          "PreliminaryEntriesStartdate":event['oes_information']['PreliminaryEntriesStartdate'],
          "PreliminaryEntriesEndDate":event['oes_information']['PreliminaryEntriesEndDate'],
          "FinalEntriesStartDate":event['oes_information']['FinalEntriesStartDate'],
          "FinalEntriesEndDate":event['oes_information']['FinalEntriesEndDate'],
          "TravelDetailsDeadline":event['oes_information']['TravelDetailsDeadline'],
          "AccomodationDetailsDeadLine":event['oes_information']['AccomodationDetailsDeadLine'],
          "RoomCacellationDeadline":event['oes_information']['RoomCacellationDeadline'],
          "CancellationDeadline":event['oes_information']['CancellationDeadline'],
          "MaxNumberofEntries":event['oes_information']['MaxNumberofEntries']
        },
        "fees_information": {
          "EventFeeId":event['fees_information']['EventFeeId']?event['fees_information']['EventFeeId']:null,          
          "CurrencyId":event['fees_information']['CurrencyId'],
          "FeeDepositPercentage":event['fees_information']['FeeDepositPercentage']
        },
        "fees_profile_type_information":event["fees_profile_type_information"],
        "hospitality_information": {
          "EventHospitalityId":event['hospitality_information']['EventHospitalityId']?event['hospitality_information']['EventHospitalityId']:null,          
          "AllowedFirstArrivalDate":event['hospitality_information']['AllowedFirstArrivalDate'],
          "AllowedLastDepartureDate":event['hospitality_information']['AllowedLastDepartureDate']
        },
        "hopitality_details_information":event["hopitality_details_information"],
        "transport_information":event["transport_information"],
        "document_information":event["document_information"],
        "logo_information": {
          "EventLogoId":event['logo_information']['EventLogoId']?event['logo_information']['EventLogoId']:null,
          "LogoTopLeft":event['logo_information']['LogoTopLeft'],
          "LogoTopRight":event['logo_information']['LogoTopRight'],
          "LogoFooter":event['logo_information']['LogoFooter'],
          "Notes":event['logo_information']['Notes'],
          "EventSubEventId":null
        },
        "equipment_information": {
          "EventEquipmentId":event['equipment_information']['EventEquipmentId']?event['equipment_information']['EventEquipmentId']:null,
          "SelectedBalls":event['equipment_information']['SelectedBalls'],
          "SelectedTables":event['equipment_information']['SelectedTables'],
          "SelectedFlooring":event['equipment_information']['SelectedFlooring'],
          "SelectedNets":event['equipment_information']['SelectedNets'],
          "NumberofCompetitionTables":event['equipment_information']['NumberofCompetitionTables'],
          "NumberofPracticeTables":event['equipment_information']['NumberofPracticeTables'],
          "EventSubEventId":null
        },
        "date_information": {
          "EventDateId":event['date_information']['EventDateId']?event['date_information']['EventDateId']:null,
          "QualificationStartDate":event['date_information']['QualificationStartDate'],
          "QualificationEndDate":event['date_information']['QualificationEndDate'],
          "MainEventStartDate":event['date_information']['MainEventStartDate'],
          "MainEventEndDate":event['date_information']['MainEventEndDate'],
          "EventSubEventId":null
        }
      }
      return result;
    }

    setBirthDate(birthDate) {
      birthDate=new Date(birthDate);
      return (birthDate.getMonth()+1) + "-" + birthDate.getDate() + "-" + birthDate.getFullYear() 
    }

    customValidation(config,object){
      return new Promise((resolve, reject) => {
        if(config && object){
          var fields=[];
          var config_copy=_.cloneDeep(config);
          if(config.module=="individuals"){
            fields=fields.concat(config.form_fields['basic_information']['1']['fields']);
            fields=fields.concat(config.form_fields['basic_information']['2']['fields']);
            // console.log("fields",fields);
            config_copy['form_fields']=fields;
            this.formValidator(object['basic_information'],fields,true).then((valid:any)=>{
              if(valid){
                fields=[];
                fields=fields.concat(config.form_fields['additional_information']['1']['fields']);
                config_copy['form_fields']=fields;
                this.formValidator(object['additional_information'],fields,true).then((valid:any)=>{
                  resolve(valid);
                });
              }else{
                resolve(false);
              }
            });
          }
          else if(config.module=="organization"){
            fields=fields.concat(config.form_fields['basic_information']['1']['fields']);
            fields=fields.concat(config.form_fields['basic_information']['2']['fields']);
            fields=fields.concat(config.form_fields['basic_information']['3']['fields']);
            fields=fields.concat(config.form_fields['basic_information']['4']['fields']);
            config_copy['form_fields']=fields;
            this.formValidator(object['basic_information'],fields,true).then((valid:any)=>{
              if(valid){
                fields=[];
                fields=fields.concat(config.form_fields['additional_information']['1']['fields']);
                fields=fields.concat(config.form_fields['additional_information']['2']['fields']);
                config_copy['form_fields']=fields;
                this.formValidator(object['additional_information'],fields,true).then((valid:any)=>{
                  resolve(valid);
                });
              }else{
                resolve(false);
              }
            });
          }else if(config.module=="events"){
            fields=fields.concat(config.form_fields['basic_information']['1']['fields']);
            fields=fields.concat(config.form_fields['basic_information']['2']['fields']);
            // console.log("fields",fields);
            config_copy['form_fields']=fields;
            this.formValidator(object['basic_information'],fields,true).then((valid:any)=>{
              if(valid){
                fields=[];
                fields=fields.concat(config.form_fields['competition_information']['1']['fields']);
                config_copy['form_fields']=fields;
                this.formValidator(object['competition_information'],fields,true).then((valid:any)=>{
                  if(valid){
                    fields=[];
                    fields=fields.concat(config.form_fields['fee_bank_account_information']['1']['fields']);
                    config_copy['form_fields']=fields;
                    this.formValidator(object['fee_bank_account_information'],fields,true).then((valid:any)=>{
                      if(valid){ 
                        fields=[];
                        fields=fields.concat(config.form_fields['oes_information']['1']['fields']);
                        config_copy['form_fields']=fields;
                        this.formValidator(object['oes_information'],fields,true).then((valid:any)=>{
                          if(valid){
                            fields=[];
                            fields=fields.concat(config.form_fields['logo_information']['1']['fields']);
                            config_copy['form_fields']=fields;
                            this.formValidator(object['logo_information'],fields,true).then((valid:any)=>{
                              if(valid){
                                fields=[];
                                fields=fields.concat(config.form_fields['date_information']['1']['fields']);
                                config_copy['form_fields']=fields;
                                this.formValidator(object['date_information'],fields,true).then((valid:any)=>{
                                  if(valid){
                                    fields=[];
                                    fields=fields.concat(config.form_fields['equipment_information']['1']['fields']);
                                    config_copy['form_fields']=fields;
                                    this.formValidator(object['equipment_information'],fields,true).then((valid:any)=>{
                                      if(valid){
                                        fields=[];
                                        fields=fields.concat(config.form_fields['fees_information']['1']['fields']);
                                        config_copy['form_fields']=fields;
                                        this.formValidator(object['fees_information'],fields,true).then((valid:any)=>{
                                          if(valid){
                                            fields=[];
                                            fields=fields.concat(config.form_fields['hospitality_information']['1']['fields']);
                                            config_copy['form_fields']=fields;
                                            this.formValidator(object['hospitality_information'],fields,true).then((valid:any)=>{
                                              resolve(valid);
                                            });
                                          }else{
                                            resolve(false);
                                          }
                                        });
                                      }else{
                                        resolve(false);
                                      }
                                    });
                                  }else{
                                    resolve(false);
                                  }
                                });
                              }else{
                                resolve(false);
                              }
                            });
                          }else{
                            resolve(false);
                          }
                        });
                      }else{
                        resolve(false);
                      }
                    });
                  }else{
                    resolve(false);
                  }
                });
              }else{
                resolve(false);
              }
            });
}else{
  resolve(true);
}
}else{
  resolve(false);
}
});
}

logout() {
  delete localStorage.auth_token;
  delete localStorage.name;
  delete localStorage.loggedInUserId;
  delete localStorage.login;
  delete localStorage.user_data;
  delete localStorage.org_view;
  delete localStorage.org_id;
  delete localStorage.role_data;
  delete localStorage['cv_section_header'];
  delete localStorage['randid'];
  delete sessionStorage['cv_view_header_sort_field'];
  window.location.reload();
}

clearAuthVariables(){
  var remove_local_storage_vars=[
  'adal.state.login','login','adal.nonce.idtoken','adal.login.error','adal.login.request','adal.error','adal.error.description','auth_token','loggedInUserId','name'
  ];
  _.each(remove_local_storage_vars,function(var_name){
    localStorage.removeItem(var_name);
  });
  return true;
}

processHPFtoSave(obj,eventId,orgid){
  var selected_packs=[],that=this;
  _.each(obj,function(profile_obj){
    _.each(profile_obj.hotels,function(hotel_obj){
      _.each(hotel_obj.rooms,function(room_obj){
        _.each(room_obj.Packs,function(pack_obj){
          // console.log("pack_obj",pack_obj);
          if(pack_obj.Event1stEntryHospitalityFeeId){
            var input={
              Event1stEntryHospitalityFeeId:pack_obj.Event1stEntryHospitalityFeeId,
              EventId:eventId,
              OrganizationId:orgid,
              ProfileTypeId:pack_obj.ProfileTypeId,
              EventHospitalityOptionDetailId:pack_obj.EventHospitalityOptionDetailId,
              PerNightRoomCount:pack_obj.PerNightRoomCount,
              RoomType:pack_obj.RoomType,
              Price:pack_obj.Price,
              WholeEventRoomCount:pack_obj.WholeEventRoomCount,
              NoOfNightsStaying:pack_obj.NoOfNightsStaying,
              WholeEventPackageSubTotal:pack_obj.WholeEventPackageSubTotal,
              PerNightPackageSubTotal:pack_obj.PerNightPackageSubTotal,
              PackType:pack_obj.PackType,
              Deleted:pack_obj.Deleted
            };
            // input['HospitalityPackData']=_.filter(that.event.hopitality_details_information,{EventHospitalityOptionDetailId:room_obj.EventHospitalityOptionDetailId})[0];
            selected_packs.push(input);
          }else{
            if(room_obj.selection && (pack_obj.PerNightRoomCount || pack_obj.WholeEventRoomCount)){
              var input={
                Event1stEntryHospitalityFeeId:pack_obj.Event1stEntryHospitalityFeeId,
                EventId:eventId,
                OrganizationId:orgid,
                ProfileTypeId:pack_obj.ProfileTypeId,
                EventHospitalityOptionDetailId:pack_obj.EventHospitalityOptionDetailId,
                PerNightRoomCount:pack_obj.PerNightRoomCount,
                RoomType:pack_obj.RoomType,
                Price:pack_obj.Price,
                WholeEventRoomCount:pack_obj.WholeEventRoomCount,
                NoOfNightsStaying:pack_obj.NoOfNightsStaying,
                WholeEventPackageSubTotal:pack_obj.WholeEventPackageSubTotal,
                PerNightPackageSubTotal:pack_obj.PerNightPackageSubTotal,
                PackType:pack_obj.PackType,
                Deleted:pack_obj.Deleted
              };
              // input['HospitalityPackData']=_.filter(that.event.hopitality_details_information,{EventHospitalityOptionDetailId:room_obj.EventHospitalityOptionDetailId})[0];
              input['HospitalityPackData']="";
              selected_packs.push(input);
            }
          }
        });
      });
    });
  });
  return selected_packs;
}

processHP(hps,HospitalityFeeEntryInfo,profile_quotas){
  var grouped_by_profile=_.groupBy(hps,'ProfileTypeId');
  // console.log("grouped_by_profile",grouped_by_profile);
  var arr=[],that=this;
  _.each(grouped_by_profile,function(profile_obj){
    // console.log("profile_obj",profile_obj);
    var ProfileTypeId=profile_obj[0].ProfileTypeId;

    var EditPack=_.filter(HospitalityFeeEntryInfo,{ProfileTypeId:ProfileTypeId});

    // console.log("EditPack",EditPack); 
    var ProfileTypeName=_.filter(that.shared.data['profile_types'],{name:profile_obj[0].ProfileTypeId})[0]['display'];
    var profile_info:any={ProfileTypeId:profile_obj[0].ProfileTypeId,ProfileTypeName:ProfileTypeName,hotels:[]};
    var grouped_by_hotel=_.groupBy(profile_obj,'OrganizationId');
    _.each(grouped_by_hotel,function(hotel_obj){
      // console.log("hotel_obj",hotel_obj);
      var hotel_info:any={OrganizationId:hotel_obj[0].OrganizationId,OrganizationName:hotel_obj[0].OrganizationId,rooms:[]};

      var grouped_by_room=_.groupBy(hotel_obj,'RoomType');
      var rooms=[];
      // console.log("grouped_by_room",grouped_by_room);
      _.each(grouped_by_room,function(room_obj:any){
        // console.log("room_obj",room_obj);
        var room_info:any={
          ProfileTypeId:room_obj[0].ProfileTypeId,
          OrganizationId:room_obj[0].OrganizationId,
          EventHospitalityOptionDetailId:room_obj[0].EventHospitalityOptionDetailId,
          RoomType:room_obj[0].RoomType,
          PricePerNightperPerson:room_obj[0].PricePerNightperPerson,
          MinimumNights:room_obj[0].MinimumNights,
          PriceWholeEventPerPerson:room_obj[0].PriceWholeEventPerPerson,
          PriceExtraNightPerPerson:room_obj[0].PriceExtraNightPerPerson,
          FreeHospitalityMen:room_obj[0].FreeHospitalityMen,
          FreeHospitalityWomen:room_obj[0].FreeHospitalityWomen,
          LateCheckOut:room_obj[0].LateCheckOut,
          EarlyCheckIn:room_obj[0].EarlyCheckIn,
          CancellationPercent:room_obj[0].CancellationPercent,
          Description:room_obj[0].Description
        };
        var quota_filter=_.filter(profile_quotas,{ProfileTypeId:room_obj[0].ProfileTypeId})[0];
        room_info.members_count_info=quota_filter;
        room_info.Price=0;      
        if(EditPack && EditPack.length){
          var EditRoom:any=_.filter(EditPack,{EventHospitalityOptionDetailId:room_obj[0].EventHospitalityOptionDetailId});
          // console.log("EditRoom",EditRoom);
          room_info.Packs=EditRoom;
          if(EditRoom.length){
            room_info.selection=true;
            that.calcPrice(EditRoom,room_info);
          }
        }
        rooms.push(room_info);
      });
      hotel_info.rooms=rooms;
      hotel_info.Price=0;
      profile_info.hotels.push(hotel_info);
      that.calcPrice(rooms,hotel_info);
      // that.priceCalcForHotel(hotel_info,profile_info);
    });
    var quota_filter=_.filter(profile_quotas,{ProfileTypeId:ProfileTypeId})[0];
    // console.log("--quota_filter--",quota_filter);
    profile_info.Price=0;
    profile_info.members_count_info=quota_filter; 
    // that.priceCalcForProfile(profile_info);   
    that.calcPrice(profile_info.hotels,profile_info);
    that.bedCalc(profile_info);
    arr.push(profile_info);
  });
  return arr;
}

processViewHP(hps,HospitalityFeeEntryInfo,profile_quotas){
  var grouped_by_profile=_.groupBy(hps,'ProfileTypeId');
  // console.log("grouped_by_profile",grouped_by_profile);
  var arr=[],that=this;
  _.each(grouped_by_profile,function(profile_obj){
    // console.log("profile_obj",profile_obj);
    var ProfileTypeId=profile_obj[0].ProfileTypeId;

    var EditPack=_.filter(HospitalityFeeEntryInfo,{ProfileTypeId:ProfileTypeId});
    if(EditPack && EditPack.length){
      // console.log("EditPack",EditPack); 
      var ProfileTypeName=_.filter(that.shared.data['profile_types'],{name:profile_obj[0].ProfileTypeId})[0]['display'];
      var profile_info:any={ProfileTypeId:profile_obj[0].ProfileTypeId,ProfileTypeName:ProfileTypeName,hotels:[]};
      var grouped_by_hotel=_.groupBy(profile_obj,'OrganizationId');
      _.each(grouped_by_hotel,function(hotel_obj){
        // console.log("hotel_obj",hotel_obj);
        var hotel_info:any={OrganizationId:hotel_obj[0].OrganizationId,OrganizationName:hotel_obj[0].OrganizationId,rooms:[]};

        var grouped_by_room=_.groupBy(hotel_obj,'RoomType');
        var rooms=[];
        // console.log("grouped_by_room",grouped_by_room);
        _.each(grouped_by_room,function(room_obj:any){
          // console.log("room_obj",room_obj);
          var room_info:any={
            ProfileTypeId:room_obj[0].ProfileTypeId,
            OrganizationId:room_obj[0].OrganizationId,
            EventHospitalityOptionDetailId:room_obj[0].EventHospitalityOptionDetailId,
            RoomType:room_obj[0].RoomType,
            PricePerNightperPerson:room_obj[0].PricePerNightperPerson,
            MinimumNights:room_obj[0].MinimumNights,
            PriceWholeEventPerPerson:room_obj[0].PriceWholeEventPerPerson,
            PriceExtraNightPerPerson:room_obj[0].PriceExtraNightPerPerson,
            FreeHospitalityMen:room_obj[0].FreeHospitalityMen,
            FreeHospitalityWomen:room_obj[0].FreeHospitalityWomen,
            LateCheckOut:room_obj[0].LateCheckOut,
            EarlyCheckIn:room_obj[0].EarlyCheckIn,
            CancellationPercent:room_obj[0].CancellationPercent,
            Description:room_obj[0].Description
          };
          var quota_filter=_.filter(profile_quotas,{ProfileTypeId:room_obj[0].ProfileTypeId})[0];
          room_info.members_count_info=quota_filter;
          room_info.Price=0;
          if(EditPack && EditPack.length){
            var EditRoom:any=_.filter(EditPack,{EventHospitalityOptionDetailId:room_obj[0].EventHospitalityOptionDetailId});
            // console.log("EditRoom",EditRoom);
            room_info.Packs=EditRoom;
            if(EditRoom.length){
              room_info.selection=true;
              that.calcPrice(EditRoom,room_info);                          
              rooms.push(room_info);
            }
          }          
        });
        hotel_info.rooms=rooms;
        hotel_info.Price=0;
        if(hotel_info.rooms.length){
          profile_info.hotels.push(hotel_info);
          that.calcPrice(rooms,hotel_info);
        }
        // that.priceCalcForHotel(hotel_info,profile_info);
      });
      var quota_filter=_.filter(profile_quotas,{ProfileTypeId:ProfileTypeId})[0];
      profile_info.Price=0;
      profile_info.members_count_info=quota_filter; 
      // that.priceCalcForProfile(profile_info);   
      that.calcPrice(profile_info.hotels,profile_info);
      that.bedCalc(profile_info);
      arr.push(profile_info);
    }
  });
  return arr;
}

calcPrice(arr,rec_obj){
  var price=0;
  _.each(arr,function(obj){
    if(obj['Price']){
      price+=obj['Price'];
    }
  });
  rec_obj['Price']=price;
}

bedCalc(profile_obj){
  // console.log("profile_obj",profile_obj);
  var people_count=0,people_total=0;
  _.each(profile_obj.hotels,function(holtel_obj){
    // console.log("holtel_obj",holtel_obj);
    _.each(holtel_obj.rooms,function(room_obj){
      // console.log("room_obj",room_obj);
      var members_count_info=room_obj.members_count_info;
      if(members_count_info.ProfileTypeName=='Players'){
        people_total=parseInt(members_count_info.MaxMalePlayers)+parseInt(members_count_info.MaxFemalePlayers);
        // people_without_bed=parseInt(members_count_info.MaxMalePlayers)+parseInt(members_count_info.MaxFemalePlayers);
      }else{
        people_total=parseInt(members_count_info.IncomingMembers);
        // people_without_bed=parseInt(members_count_info.IncomingMembers);
      } 
      _.each(room_obj.Packs,function(obj){
        // console.log("obj",obj);
        if(!obj['Deleted']){
          if(obj.PackType=='per_night'){                 
            if(obj.RoomType=='Double'){
              people_count+=obj.PerNightRoomCount*2;
            }else if(obj.RoomType=='Single'){
              people_count+=obj.PerNightRoomCount*1;
            }
          }else  if(obj.PackType=='whole_event'){
            if(obj.RoomType=='Double'){
              people_count+=obj.WholeEventRoomCount*2;
            }else if(obj.RoomType=='Single'){
              people_count+=obj.WholeEventRoomCount*1;
            }
          }
        }
      });
    });    
  });
  profile_obj.people_total=people_total;
  profile_obj.people_without_bed=profile_obj.people_total-people_count;
}

bedCalc1(room_obj){
  var people_count=0;
  // console.log("room_obj",room_obj);
  _.each(room_obj.Packs,function(obj){
    // console.log("obj",obj);
    if(!obj['Deleted']){
      if(obj.PackType=='per_night'){
        people_count=obj.PerNightRoomCount;
        if(obj.RoomType=='Double'){
          people_count=people_count*2;
        }
      }else if(obj.PackType=='whole_event'){
        people_count=obj.WholeEventRoomCount;
        if(obj.RoomType=='Double'){
          people_count=people_count*2;
        }
      }
    }
  });
  room_obj.people_without_bed=room_obj.people_total-people_count;
}

processProfileQuotas(event,EventOrgProfileQuotas){
  var participation_fee=[],profile_quotas=[],maxAllowedTotal=0;
  var that=this;
  _.each(event.profile_quota_information,function(obj){
    obj['ProfileTypeName']=_.filter(that.shared.data['profile_types'],{name:obj.ProfileTypeId})[0]['display'];
    var  filter:any=_.filter(EventOrgProfileQuotas,{'ProfileTypeId':obj.ProfileTypeId})[0];
    if(filter && filter.EventOrgProfileQuotaId){
      obj['EventOrgProfileQuotaId']=filter['EventOrgProfileQuotaId'];
    }else{
      obj['EventOrgProfileQuotaId']=null;
    }
    if(filter && filter.IncomingMembers){
      obj['IncomingMembers']=filter['IncomingMembers'];
    }else{
      obj['IncomingMembers']=null;
    }
    if(filter && filter.MalePlayers){
      obj['MalePlayers']=filter['MalePlayers'];
    }else{
      obj['MalePlayers']=null;
    }
    if(filter && filter.FemalePlayers){
      obj['FemalePlayers']=filter['FemalePlayers'];
    }else{
      obj['FemalePlayers']=null;
    }
    var participation_fee_obj={
      ProfileTypeId:obj.ProfileTypeId,
      ProfileTypeName:obj['ProfileTypeName'],
      Participants:0
    };
    if(obj['ProfileTypeName']!='Players'){
      if(obj['IncomingMembers']!=null){
        participation_fee_obj['Participants']=obj['IncomingMembers'];
      }
    }
    else if(obj['ProfileTypeName']=='Players'){
      if(obj['MalePlayers']!=null){
        participation_fee_obj['Participants']+=parseInt(obj['MalePlayers']);
      }
      if(obj['FemalePlayers']!=null){
        participation_fee_obj['Participants']+=parseInt(obj['FemalePlayers']);
      }
    }
    var fee_filter:any=_.filter(event.fees_profile_type_information,{ProfileTypeId:obj.ProfileTypeId});
    // console.log("fee_filter",fee_filter);
    if(fee_filter && fee_filter[0]){
      participation_fee_obj['fee']=fee_filter[0].ParticipationFee;
      participation_fee_obj['cancellation_fee']=fee_filter[0].CancellationFee;
    }else{
      participation_fee_obj['fee']=false;
    }
    participation_fee.push(participation_fee_obj);
    profile_quotas.push(obj); 

    if(obj.MaxAllowedMembers){
      maxAllowedTotal+=obj.MaxAllowedMembers;
    }
    if(obj.MaxMalePlayers){
      maxAllowedTotal+=obj.MaxMalePlayers;
    }
    if(obj.MaxFemalePlayers){
      maxAllowedTotal+=obj.MaxFemalePlayers;
    }
  });
  return {"participation_fee":participation_fee,"profile_quotas":profile_quotas,"maxAllowedTotal":maxAllowedTotal};
}

processProfileQuotastoSave(eventOrgProfileQuotas,profile_quotas,eventId,orgid){
  var org_profile_quotas={};
  _.each(profile_quotas,function(obj){
    if(obj.EventOrgProfileQuotaId){
      org_profile_quotas['EventOrgProfileQuotaId']=obj['EventOrgProfileQuotaId'];
    }else{
      org_profile_quotas['EventOrgProfileQuotaId']=null;
    }
    org_profile_quotas['EventId']=eventId;
    org_profile_quotas['ProfileTypeId']=obj['ProfileTypeId'];
    org_profile_quotas['OrganizationId']=orgid;
    if(obj.IncomingMembers){
      org_profile_quotas['IncomingMembers']=obj['IncomingMembers'];
    }else{
      org_profile_quotas['IncomingMembers']=null;
    }
    if(obj.MalePlayers){
      org_profile_quotas['MalePlayers']=obj['MalePlayers'];
    }else{
      org_profile_quotas['MalePlayers']=null;
    }
    if(obj.FemalePlayers){
      org_profile_quotas['FemalePlayers']=obj['FemalePlayers'];
    }else{
      org_profile_quotas['FemalePlayers']=null;
    }
    eventOrgProfileQuotas.push(org_profile_quotas);
    org_profile_quotas={};
  });
  return eventOrgProfileQuotas;
}

}
